import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "application__menu" }
const _hoisted_2 = { class: "application__btn-actions gap-1200" }
const _hoisted_3 = ["data-cy"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ApplicationHeader = _resolveComponent("ApplicationHeader")!
  const _component_LayoutSection = _resolveComponent("LayoutSection")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, { class: "application" }, {
    default: _withCtx(() => [
      ($options.application)
        ? (_openBlock(), _createBlock(_component_LayoutSection, {
            key: 0,
            class: "application__header",
            background: "primary",
            noVerticalPadding: "",
            fullWidthContent: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ApplicationHeader, { application: $options.application }, null, 8, ["application"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_LayoutSection, {
        background: "primary",
        noVerticalPadding: ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.btnActions, (btn) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: btn.route,
                  class: _normalizeClass([
              'action',
              btn.route === $options.activeRoute && 'btn-action-active',
            ]),
                  "data-cy": `application-menu-${btn.label.toLowerCase()}`
                }, [
                  _createVNode(_component_router_link, {
                    class: "action-title",
                    to: { name: btn.route, params: { id: $props.id } }
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("h6", null, _toDisplayString(btn.label) + " " + _toDisplayString(btn.route === "application" ? `(${$options.totalContractsCount})` : ""), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ], 10, _hoisted_3))
              }), 128))
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_LayoutSection, {
        class: "application__content",
        background: "secondary",
        extendedBottomPadding: ""
      }, {
        default: _withCtx(() => [
          ($options.application && $options.application.id === $options.applicationId)
            ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}