<template>
  <div v-if="!isDoingInitialLoading" class="consumers-api">
    <div v-if="hasConsumer">
      <div class="consumers-api__title-and-action-header">
        <h3>{{ contents.consumersTitle }}</h3>

        <div class="consumers-api__action-header">
          <MButton
            icon="DownloadWeb24"
            :label="contents.exportConsumers"
            theme="bordered-neutral"
            size="s"
            @click="exportConsumers"
          />
          <MButton
            class="consumers-api__send-email-button"
            icon="ContactMail24"
            :label="contents.sendAnEmailToAllUsers"
            size="s"
            @click="openSendEmailModal"
          />
        </div>
      </div>

      <CardContainer>
        <DvpTabs v-model="currentTab" :tabs="tabItems" align="full" />

        <div class="consumers-api__tab-content">
          <div class="consumers-api__tab-content-header">
            <MTextInput
              data-cy="search-consumer-application"
              class="consumers-api__search-application"
              type="search"
              icon="DisplaySearch32"
              size="s"
              :modelValue="searchTerm"
              :placeholder="contents.searchApplicationPlaceholder"
              @update:modelValue="onSearchInputChange"
            />
          </div>

          <ConsumersApiTable
            :pagedContracts="currentContracts"
            :currentTab="currentTab"
            @update:currentPage="loadPageForCurrentSearch($event)"
          />
        </div>
      </CardContainer>
    </div>

    <div v-else class="consumers-api__no-consumer-content">
      <h4 class="consumers-api__no-consumer-title">
        {{ contents.noApplicationAreUsing }} {{ currentApi.name }}
      </h4>
      <RouterLink :to="{ name: 'applicationCreate' }">
        <MButton :label="contents.createAnAppToUseApi" size="l" theme="solid" />
      </RouterLink>
    </div>
  </div>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";
import { markRaw } from "vue";

import CardContainer from "@/commons/components/CardContainer.vue";
import { DvpTabsItem } from "@/commons/components/DvpTabs/DvpTabs.type";
import DvpTabs from "@/commons/components/DvpTabs/DvpTabs.vue";

import ConsumersApiTable from "./ConsumersApiTable.vue";
import SendEmailLayerModal from "./SendEmailLayerModal.vue";

import { SIZE_PER_PAGE_CONSUMERS } from "@/commons/libs/utils/contractUtils";
import { debounce } from "@/commons/libs/utils/debouncer";
import { getApiRepository } from "@/commons/repositories/libs/get-api-repository";
import { downloadBlobData } from "@/commons/utils/download-utils";
import { flagLoadingProcess } from "@/commons/utils/flagProcess.utils";

import { ContractParameters } from "@/commons/domain/repositories/contract-repository";

import { EApplicationType, EContractStatus } from "@/commons/store/types";

import contents from "@/manager/contents/consumers-api";
import contentsOfSendEmailLayerModal from "@/manager/contents/send-email-layer-modal";

export default {
  components: {
    MTextInput,
    ConsumersApiTable,
    MButton,
    DvpTabs,
    CardContainer,
  },
  data() {
    return {
      contents,
      searchTerm: "",
      currentTab: undefined as EApplicationType,
      EApplicationType,
      hasConsumer: false,
    };
  },
  computed: {
    currentApi() {
      return this.$store.getters["currentApi"];
    },
    // Any additional loading process should be declared in Settings.vue
    isDoingInitialLoading() {
      return this.$store.getters["isLoadingProperty"](
        "consumerApiInitialLoading",
      );
    },
    contracts() {
      return (
        this.$store.getters["contracts"] &&
        this.$store.getters["contracts"][EApplicationType.ALL]
      );
    },
    sandboxContracts() {
      return (
        this.$store.getters["contracts"] &&
        this.$store.getters["contracts"][EApplicationType.SANDBOX]
      );
    },
    currentContracts() {
      return this.currentTab === EApplicationType.ALL
        ? this.contracts
        : this.sandboxContracts;
    },
    tabItems(): DvpTabsItem[] {
      return [
        {
          label: `${contents.applications} (${this.contracts.totalCount})`,
          value: EApplicationType.ALL,
          disabled: this.contracts.totalCount === 0,
          class: "consumers-api__application-tab",
        },
        {
          label: `${contents.sandboxes} (${this.sandboxContracts.totalCount})`,
          value: EApplicationType.SANDBOX,
          disabled: this.sandboxContracts.totalCount === 0,
          class: "consumers-api__sandbox-tab",
        },
      ];
    },
  },
  watch: {
    currentTab(newValue, oldValue) {
      if (oldValue != undefined) {
        // Reinit previous tab search and related results
        this.searchTerm = "";
        this.loadContractsOfApplicationType(oldValue);
      }
    },
  },
  async created() {
    this.debouncedLoadPageForCurrentSearch = debounce(
      this.loadPageForCurrentSearch,
    );

    await flagLoadingProcess("consumerApiInitialLoading", async () => {
      await this.loadContractsOfApplicationType(EApplicationType.ALL);
      await this.loadContractsOfApplicationType(EApplicationType.SANDBOX);

      // hasContract has to be calculated when there is no search yet, otherwise, if the template depends on it, the template would also depend on the search
      this.hasConsumer =
        this.contracts?.totalCount > 0 || this.sandboxContracts?.totalCount > 0;

      this.currentTab =
        this.contracts?.totalCount > 0
          ? EApplicationType.ALL
          : EApplicationType.SANDBOX;
    });
  },
  methods: {
    exportConsumers() {
      getApiRepository()
        .exportConsumers(this.currentApi.id)
        .then((exportResponse) =>
          downloadBlobData(exportResponse.data, exportResponse.filename),
        );
    },
    openSendEmailModal() {
      this.$store.commit("openLayerModal", {
        title: contentsOfSendEmailLayerModal.modalTitle,
        component: markRaw(SendEmailLayerModal),
        props: {
          apiId: this.currentApi.id,
        },
        listeners: {},
      });
    },
    async loadContracts(params: ContractParameters) {
      await this.$store.dispatch("getContracts", params);
    },
    async loadContractsForSearchCriteria({
      applicationTypes,
      applicationName,
      page,
      cancellable = false,
    }) {
      await this.loadContracts({
        apiId: this.currentApi.id,
        status: [EContractStatus.ENABLED, EContractStatus.DISABLED],
        size: SIZE_PER_PAGE_CONSUMERS,
        applicationTypes,
        applicationName,
        page,
        cancellable,
      });
    },
    async loadPageForCurrentSearch(page: number): Promise<void> {
      await this.loadContractsForSearchCriteria({
        applicationTypes: [this.currentTab],
        applicationName: this.searchTerm,
        page,
        cancellable: true,
      });
    },
    async loadContractsOfApplicationType(
      applicationType: EApplicationType,
    ): Promise<void> {
      await this.loadContractsForSearchCriteria({
        applicationTypes: [applicationType],
        applicationName: "",
        page: 1,
      });
    },
    onSearchInputChange(newSearchValue: string): void {
      this.searchTerm = newSearchValue;
      this.debouncedLoadPageForCurrentSearch(1);
    },
  },
};
</script>

<style lang="scss">
.consumers-api__title-and-action-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.consumers-api__action-header {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-bottom: 1rem;
}

.consumers-api__tab-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
}

.consumers-api__tab-content-header {
  display: flex;
  justify-content: flex-end;
}

.consumers-api__search-application {
  min-width: 20rem;
}

.consumers-api__no-consumer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
</style>
