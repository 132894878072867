<template>
  <Layout class="settings">
    <template #secondaryNavbar>
      <template v-if="isLoading || currentApi === undefined">
        <SecondaryNavbarLoading />
      </template>
      <template v-else>
        <ApiSettingsSecondaryNavbar :api="currentApi" />
      </template>
    </template>

    <template #sideMenu>
      <SettingsMenu :menu-id="menuId" />
    </template>

    <PageLoader v-if="isLoading" />

    <LayoutSection
      v-if="!isLoading && (displayApiAlerts || displayKongNotification)"
      suppressBottomPadding
    >
      <NotificationForKongSynchronization
        v-if="displayKongNotification"
        configurationType="Api"
      />

      <ApiAlerts v-if="displayApiAlerts" :checkList="checkList" />
    </LayoutSection>

    <LayoutSection fullHeight>
      <router-view v-if="currentApi" />
    </LayoutSection>
  </Layout>
</template>

<script setup lang="ts">
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import Layout from "@/commons/components/Layout.vue";
import LayoutSection from "@/commons/components/LayoutSection.vue";
import PageLoader from "@/commons/components/Loading/PageLoader.vue";
import SecondaryNavbarLoading from "@/commons/components/Navbar/SecondaryNavbarLoading.vue";
import NotificationForKongSynchronization from "@/dashboard/components/NotificationForKongSynchronization.vue";
import ApiSettingsSecondaryNavbar from "@/manager/components/ApiSettingsSecondaryNavbar.vue";
import SettingsMenu from "@/manager/views/SettingsMenu/SettingsMenu.vue";

import ApiAlerts from "./ApiAlerts.vue";

import { EApiPublicationStatus } from "@/commons/store/types";
import { ApiCheck } from "@/manager/store/types";

const props = defineProps({
  menuId: {
    type: String,
    default: "",
  },
});

const store = useStore();
const route = useRoute();

// If any of these properties are loading, the loader will be displayed in any Api Settings section
const API_STORE_LOADING_PROPERTIES = [
  "api",
  "consumerApiInitialLoading",
  "pendingRequestSummaries",
];

const hasApiStatusAsPendingDeploy = computed((): boolean => {
  return (
    currentApi.value?.publicationStatus ===
    EApiPublicationStatus.NOT_SYNCHRONIZED
  );
});

const currentApi = computed(() => {
  return store.getters["currentApi"];
});

const isLoading = computed((): boolean => {
  return store.getters["isLoadingAnyOfProperties"](
    API_STORE_LOADING_PROPERTIES,
  );
});

const checkList = computed((): ApiCheck[] => {
  return store.getters["checkList"].filter(
    (check: ApiCheck) => check.menuPath === props.menuId && check.show,
  );
});

const frontendConfig = computed(() => {
  return store.getters["config/frontendConfig"];
});

const displayKongInformation = computed((): boolean => {
  return frontendConfig.value.displayKongInformation;
});

const displayKongNotification = computed((): boolean => {
  return (
    currentApi.value &&
    displayKongInformation.value &&
    hasApiStatusAsPendingDeploy.value
  );
});

const displayApiAlerts = computed((): boolean => {
  return !isLoading.value && checkList.value.length > 0;
});

onMounted(async () => {
  const apiId = route.params.id;
  await store.dispatch("loadApi", {
    apiId,
  });
  await store.dispatch("deprecatableZones", {
    apiId,
    params: {
      publishable: false,
      deprecatable: true,
    },
  });
});
</script>
