<template>
  <Layout class="use-cases">
    <LayoutSection>
      <div class="use-cases__title">
        <div class="use-cases__description">{{ contents.description }}</div>
        <h1>{{ contents.title }}</h1>
      </div>
    </LayoutSection>
    <LayoutSection noVerticalPadding>
      <UseCaseCard
        class="use-cases__card"
        :title="useCase.title"
        :description="useCase.description"
        :path="`/usecases/${useCase.slug}`"
      />
    </LayoutSection>
    <LayoutSection>
      <ContactUs />
    </LayoutSection>
    <LayoutSection fullWidthContent noHorizontalPadding noVerticalPadding>
      <FooterMenus />
    </LayoutSection>
  </Layout>
</template>

<script setup lang="ts">
import ContactUs from "@/commons/components/ContactUs.vue";
import FooterMenus from "@/commons/components/FooterMenus.vue";
import Layout from "@/commons/components/Layout.vue";
import LayoutSection from "@/commons/components/LayoutSection.vue";
import UseCaseCard from "@/commons/components/UseCaseCard.vue";

import useCase from "@/showcase/contents/use-cases";

const contents = {
  title: "Discover all our use cases made by partners and users",
  description: "Use case studies",
};
</script>

<style lang="scss">
@import "~@/commons/assets/styles/lib/breakpoints-mixin";

.use-cases__title {
  position: relative;
  width: 35rem;

  @include respond-to("mobile-large") {
    width: 80vw;
  }
}

.use-cases__description {
  font-weight: 200;
  color: var(--color-leroy-merlin);
  text-transform: uppercase;
}

.use-cases .layout__content {
  position: relative;

  &::before {
    position: absolute;
    top: -20rem;
    left: 0;
    width: 100vw;
    height: 100%;
    content: "";

    background-color: var(--color-leroy-merlin);
    transform: skewX(-73deg);
  }
}

.use-cases__card {
  position: relative;
}
</style>
