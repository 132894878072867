<script setup lang="ts">
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";

import UsersList from "@/commons/components/ManageGroups/UsersList.vue";

import { flagLoadingProcess } from "@/commons/utils/flagProcess.utils";

import { AclUser } from "@/commons/domain/models/acl-user";
import { findGroupUsers } from "@/commons/services/group/group-users-service";

const props = defineProps({
  groupId: {
    type: String,
    required: true,
  },
});

const store = useStore();

const usersList = ref([] as AclUser[]);

const loadUsers = async () => {
  usersList.value = await findGroupUsers(props.groupId);
};

const isLoading = computed(() => {
  return store.getters["isLoadingProperty"]("loadGroupUsers");
});

onMounted(async () => {
  await flagLoadingProcess("loadGroupUsers", async () => {
    await loadUsers();
  });
});
</script>

<template>
  <!-- UsersList component with loading users data -->
  <UsersList
    :groupId="groupId"
    :users="usersList"
    :isLoading="isLoading"
    v-bind="$attrs"
  />
</template>
