<template>
  <div v-if="!isLoadingPendingRequests" class="access-restriction">
    <CardContainer class="access-restriction__content">
      <div class="access-restriction__title">
        <h4>{{ contents.titleAccessRestrictions }}</h4>
        <ViewHelpLink :documentationAnchor="contents.viewHelpDocAnchor" />
      </div>

      <MNotification
        v-if="
          !userIsAdmin &&
          !apiHasPendingChangeToPublic &&
          selectedPrivacy === EPrivacyName.PUBLIC
        "
        type="warning"
      >
        {{ contents.alerteMessageAdministratorApproval }}
      </MNotification>

      <MNotification
        v-if="
          selectedPrivacy === EPrivacyName.PARTNER &&
          Object.values(groupsAclsSelected).length === 0
        "
        type="warning"
      >
        {{ contents.alertMessageAddPartners }}
      </MNotification>

      <DvpListBullet
        :number="contents.numberOne"
        :title="contents.titleStepOne"
      />

      <AccessRestrictionSelector
        :apiPublicAccessRestriction="apiPublicAccessRestriction"
        :selectedPrivacy="selectedPrivacy"
        :apiHasPendingChangeToPublic="apiHasPendingChangeToPublic"
        @change="changeAccessRestriction"
      />
      <template v-if="canSetAccessType">
        <DvpListBullet
          :number="contents.numberTwo"
          :title="contents.titleStepTwoValidateAccess"
        />

        <MRadioGroup
          v-model="selectedAccessPolicy"
          class="access-restriction__on-demand-access"
          inline
          :options="[
            {
              id: EAccessPolicy.ON_DEMAND,
              label: contents.yes,
              value: EAccessPolicy.ON_DEMAND,
            },
            {
              id: EAccessPolicy.SELF_SERVICE,
              label: contents.no,
              value: EAccessPolicy.SELF_SERVICE,
            },
          ]"
        />
      </template>

      <template v-if="canHoldAcl">
        <DvpListBullet
          :number="canSetAccessType ? contents.numberThree : contents.numberTwo"
          :title="
            selectedPrivacy === EPrivacyName.PRIVATE
              ? contents.titleStepTwoInternalGroups
              : contents.titleStepTwoGroups
          "
        />

        <MNotification
          v-if="
            (selectedPrivacy === EPrivacyName.PRIVATE &&
              Object.values(groupsAclsSelected).length == 0) ||
            selectedPrivacy === EPrivacyName.PARTNER
          "
          type="information"
        >
          {{
            selectedPrivacy === EPrivacyName.PRIVATE
              ? contents.alertMessagePrivate
              : contents.alertMessagePartner
          }}
        </MNotification>

        <ACLsManager
          :acls="Object.values(currentApi.acl.groups)"
          :listHeader="contents.aclManagerListHeader"
          :listEmptyStateMessage="contents.aclManagerListEmptyStateMessage"
          :withPartners="selectedPrivacy === EPrivacyName.PARTNER"
          @submit="addGroupAcl"
        >
          <ACL
            v-for="acl in Object.values(groupsAclsSelected)"
            :key="acl.id"
            :groupId="acl.id"
            :groupName="acl.name"
            :provider="acl.provider"
            @remove="removeGroupAcl"
          />
        </ACLsManager>
      </template>

      <MNotification
        v-if="
          selectedPrivacy === EPrivacyName.PUBLIC &&
          !userIsAdmin &&
          apiHasPendingChangeToPublic
        "
        type="information"
      >
        {{ contents.alertMessageRequestPending }}
      </MNotification>

      <div class="access-restriction__btn-validate">
        <RouterLink
          v-if="
            selectedPrivacy === EPrivacyName.PUBLIC &&
            apiHasPendingChangeToPublic
          "
          :to="publicPolicyPendingRequestsRoute"
        >
          <MButton theme="bordered" :label="contents.viewYourRequest" />
        </RouterLink>
        <MButton
          v-else
          :label="contents.validateAccessPreferences"
          :disabled="!canUpdateApiAccessRestriction"
          @click="updateApiAclsAndAccessRestriction"
        />
      </div>
    </CardContainer>
  </div>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import MRadioGroup from "@mozaic-ds/vue-3/src/components/radio/MRadioGroup.vue";
import { markRaw } from "vue";

import ACL from "@/commons/components/ACLsManager/ACL.vue";
import ACLsManager from "@/commons/components/ACLsManager/ACLsManager.vue";
import CardContainer from "@/commons/components/CardContainer.vue";
import DvpListBullet from "@/commons/components/DvpListBullet.vue";
import ViewHelpLink from "@/commons/components/UserDocumentationLinks/ViewHelpLink.vue";

import AccessRestrictionSelector from "./AccessRestrictionSelector.vue";
import RequestApiPublicAccessModal from "./RequestApiPublicAccessModal.vue";

import { convertRouteToHref } from "@/commons/utils/route-utils";

import { AclGroup } from "@/commons/domain/models/acl-group";
import { Group } from "@/commons/domain/models/group";

import {
  EAccessPolicy,
  EPrivacyName,
  ERequestFilteringVisibility,
  ERequestType,
  ERole,
} from "@/commons/store/types";

import contents from "@/manager/contents/access-restriction";
import contentsRequestApiPublicAccessModal from "@/manager/contents/request-api-public-access-modal";

export default {
  name: "AccessRestriction",
  components: {
    ViewHelpLink,
    CardContainer,
    ACLsManager,
    MRadioGroup,
    AccessRestrictionSelector,
    ACL,
    MNotification,
    MButton,
    DvpListBullet,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      contents,
      EPrivacyName,
      aclsSuggestions: [],
      selectedPrivacy: null,
      EAccessPolicy,
      selectedAccessPolicy: EAccessPolicy.ON_DEMAND,
      groupsAclsSelected: {},
    };
  },
  computed: {
    canSetAccessType() {
      return (
        [EPrivacyName.INTERNAL, EPrivacyName.PARTNER].includes(
          this.selectedPrivacy,
        ) ||
        (this.selectedPrivacy === EPrivacyName.PUBLIC &&
          (this.userIsAdmin || !this.apiHasPendingChangeToPublic))
      );
    },
    canHoldAcl() {
      return (
        this.selectedPrivacy === EPrivacyName.PRIVATE ||
        this.selectedPrivacy === EPrivacyName.PARTNER
      );
    },
    currentApi() {
      return this.$store.getters["currentApi"];
    },
    // Any additional loading process should be declared in Settings.vue
    isLoadingPendingRequests(): boolean {
      return this.$store.getters["isLoadingProperty"](
        "pendingRequestSummaries",
      );
    },
    user() {
      return this.$store.getters["user"];
    },
    pendingAccessPolicyRequests() {
      return this.$store.getters["currentApiPendingAccessPolicyRequests"];
    },
    userIsAdmin() {
      return this.user && this.user.isAdmin;
    },
    apiPublicAccessRestriction() {
      return this.$store.getters["apiPublicAccessRestriction"];
    },
    canUpdateApiAccessRestriction() {
      return (
        !this.apiHasPendingChangeToPublic &&
        (this.currentApi.privacy !== this.selectedPrivacy ||
          (this.currentApi.accessPolicy !== this.selectedAccessPolicy &&
            this.currentApi.accessPolicy !== EAccessPolicy.PRIVATE) ||
          this.hasAclChanged)
      );
    },
    hasAclChanged() {
      let currentGroupIds = Object.values(this.groupAcls).map(
        (group: AclGroup) => group.id,
      );
      let selectedGroupIds = Object.values(this.groupsAclsSelected).map(
        (group: AclGroup) => group.id,
      );

      return (
        currentGroupIds.filter((groupId) => !selectedGroupIds.includes(groupId))
          .length > 0 ||
        selectedGroupIds.filter((groupId) => !currentGroupIds.includes(groupId))
          .length > 0
      );
    },
    apiHasPendingChangeToPublic() {
      return !!Object.keys(this.pendingAccessPolicyRequests).length;
    },
    groupAcls() {
      return Object.values(this.currentApi.acl.groups)
        .filter(
          (group: Group) => group.role.id === this.roleForAccessRestriction,
        )
        .reduce((acc, group: Group) => {
          acc[group.id] = group;
          return acc;
        }, {});
    },
    immutableGroupAcls() {
      return Object.values(this.currentApi.acl.groups)
        .filter(
          (group: Group) => group.role.id !== this.roleForAccessRestriction,
        )
        .reduce((acc, group: Group) => {
          acc[group.id] = group;
          return acc;
        }, {});
    },
    roleForAccessRestriction() {
      return ERole.VIEWER;
    },
    publicPolicyPendingRequestsRoute() {
      return {
        name: "requestManager",
        query: {
          types: ERequestType.UPDATE_ACCESS_POLICY,
          visibility: ERequestFilteringVisibility.SUBMITTED,
          page: 1,
        },
      };
    },
    publicPolicyRequestShouldBeValidated(): boolean {
      return !this.userIsAdmin && this.selectedPrivacy === EPrivacyName.PUBLIC;
    },
  },
  watch: {
    currentApi() {
      this.init();
    },
  },
  async mounted() {
    this.init();
  },
  methods: {
    convertRouteToHref,
    async updateApiAclsAndAccessRestriction() {
      if (this.canUpdateApiAccessRestriction) {
        if (this.publicPolicyRequestShouldBeValidated) {
          this.openRequestApiPublicAccessModalAndUpdate();
        } else {
          let groups = {
            ...this.immutableGroupAcls,
            ...this.groupsAclsSelected,
          };
          await this.$store.dispatch("updateApiAndAcl", {
            payloadUpdateApi: {
              id: this.currentApi.id,
              privacy: this.selectedPrivacy,
              accessPolicy: this.canSetAccessType
                ? this.selectedAccessPolicy
                : EAccessPolicy.PRIVATE,
            },
            groups: this.hasAclChanged && groups,
          });
        }
      }
    },
    openRequestApiPublicAccessModalAndUpdate() {
      this.$store.commit("openLayerModal", {
        title: contentsRequestApiPublicAccessModal.modalTitle,
        component: markRaw(RequestApiPublicAccessModal),
        listeners: {
          onSubmit: (comment: string) => {
            this.updatePrivacyAndAccessPolicy(comment);
          },
        },
      });
    },
    async updatePrivacyAndAccessPolicy(comment: string) {
      if (
        this.currentApi.privacy !== this.selectedPrivacy ||
        this.currentApi.accessPolicy !== this.selectedAccessPolicy
      ) {
        await this.$store.dispatch("updateApi", {
          id: this.currentApi.id,
          privacy: this.selectedPrivacy,
          accessPolicy: this.canSetAccessType
            ? this.selectedAccessPolicy
            : EAccessPolicy.PRIVATE,
          comment,
        });
      }
    },
    changeAccessRestriction(newAccessRestriction) {
      this.aclsSuggestions = [];
      this.selectedPrivacy = newAccessRestriction;
    },
    addGroupAcl(group) {
      this.groupsAclsSelected = {
        ...this.groupsAclsSelected,
        [group.id]: {
          id: group.id,
          role: { id: this.roleForAccessRestriction },
          name: group.name,
          provider: group.provider,
        },
      };
    },
    async removeGroupAcl(id) {
      const result = await this.$swal({
        titleText: this.contents.removeGroupAccessModalTitle,
        text: this.contents.removeGroupAccessModalText,
      });
      if (result.isConfirmed) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [id]: aclToRemove, ...groupsAclsSelected } =
          this.groupsAclsSelected;
        this.groupsAclsSelected = groupsAclsSelected;
      }
    },
    init() {
      this.$store.dispatch(
        "fetchPendingAccessPolicyRequests",
        this.currentApi.id,
      );
      this.selectedPrivacy = this.currentApi.privacy;
      this.selectedAccessPolicy =
        this.currentApi.privacy === EPrivacyName.PRIVATE
          ? EAccessPolicy.SELF_SERVICE
          : this.currentApi.accessPolicy;
      this.groupsAclsSelected = this.groupAcls;
    },
  },
};
</script>

<style>
.access-restriction {
  max-width: 80rem;
}

.access-restriction__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.access-restriction__content {
  display: flex;
  flex-direction: column;
  gap: var(--base-spacing);
}

.access-restriction__on-demand-access {
  padding: 0 3rem;
}

.access-restriction__btn-validate {
  display: flex;
  justify-content: center;

  margin-top: var(--base-spacing);
}
</style>
