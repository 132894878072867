import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "access-restriction"
}
const _hoisted_2 = { class: "access-restriction__title" }
const _hoisted_3 = { class: "access-restriction__btn-validate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ViewHelpLink = _resolveComponent("ViewHelpLink")!
  const _component_MNotification = _resolveComponent("MNotification")!
  const _component_DvpListBullet = _resolveComponent("DvpListBullet")!
  const _component_AccessRestrictionSelector = _resolveComponent("AccessRestrictionSelector")!
  const _component_MRadioGroup = _resolveComponent("MRadioGroup")!
  const _component_ACL = _resolveComponent("ACL")!
  const _component_ACLsManager = _resolveComponent("ACLsManager")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_CardContainer = _resolveComponent("CardContainer")!

  return (!$options.isLoadingPendingRequests)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_CardContainer, { class: "access-restriction__content" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h4", null, _toDisplayString($data.contents.titleAccessRestrictions), 1),
              _createVNode(_component_ViewHelpLink, {
                documentationAnchor: $data.contents.viewHelpDocAnchor
              }, null, 8, ["documentationAnchor"])
            ]),
            (
          !$options.userIsAdmin &&
          !$options.apiHasPendingChangeToPublic &&
          $data.selectedPrivacy === $data.EPrivacyName.PUBLIC
        )
              ? (_openBlock(), _createBlock(_component_MNotification, {
                  key: 0,
                  type: "warning"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($data.contents.alerteMessageAdministratorApproval), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (
          $data.selectedPrivacy === $data.EPrivacyName.PARTNER &&
          Object.values($data.groupsAclsSelected).length === 0
        )
              ? (_openBlock(), _createBlock(_component_MNotification, {
                  key: 1,
                  type: "warning"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($data.contents.alertMessageAddPartners), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_DvpListBullet, {
              number: $data.contents.numberOne,
              title: $data.contents.titleStepOne
            }, null, 8, ["number", "title"]),
            _createVNode(_component_AccessRestrictionSelector, {
              apiPublicAccessRestriction: $options.apiPublicAccessRestriction,
              selectedPrivacy: $data.selectedPrivacy,
              apiHasPendingChangeToPublic: $options.apiHasPendingChangeToPublic,
              onChange: $options.changeAccessRestriction
            }, null, 8, ["apiPublicAccessRestriction", "selectedPrivacy", "apiHasPendingChangeToPublic", "onChange"]),
            ($options.canSetAccessType)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createVNode(_component_DvpListBullet, {
                    number: $data.contents.numberTwo,
                    title: $data.contents.titleStepTwoValidateAccess
                  }, null, 8, ["number", "title"]),
                  _createVNode(_component_MRadioGroup, {
                    modelValue: $data.selectedAccessPolicy,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.selectedAccessPolicy) = $event)),
                    class: "access-restriction__on-demand-access",
                    inline: "",
                    options: [
            {
              id: $data.EAccessPolicy.ON_DEMAND,
              label: $data.contents.yes,
              value: $data.EAccessPolicy.ON_DEMAND,
            },
            {
              id: $data.EAccessPolicy.SELF_SERVICE,
              label: $data.contents.no,
              value: $data.EAccessPolicy.SELF_SERVICE,
            },
          ]
                  }, null, 8, ["modelValue", "options"])
                ], 64))
              : _createCommentVNode("", true),
            ($options.canHoldAcl)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                  _createVNode(_component_DvpListBullet, {
                    number: $options.canSetAccessType ? $data.contents.numberThree : $data.contents.numberTwo,
                    title: 
            $data.selectedPrivacy === $data.EPrivacyName.PRIVATE
              ? $data.contents.titleStepTwoInternalGroups
              : $data.contents.titleStepTwoGroups
          
                  }, null, 8, ["number", "title"]),
                  (
            ($data.selectedPrivacy === $data.EPrivacyName.PRIVATE &&
              Object.values($data.groupsAclsSelected).length == 0) ||
            $data.selectedPrivacy === $data.EPrivacyName.PARTNER
          )
                    ? (_openBlock(), _createBlock(_component_MNotification, {
                        key: 0,
                        type: "information"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($data.selectedPrivacy === $data.EPrivacyName.PRIVATE
              ? $data.contents.alertMessagePrivate
              : $data.contents.alertMessagePartner), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ACLsManager, {
                    acls: Object.values($options.currentApi.acl.groups),
                    listHeader: $data.contents.aclManagerListHeader,
                    listEmptyStateMessage: $data.contents.aclManagerListEmptyStateMessage,
                    withPartners: $data.selectedPrivacy === $data.EPrivacyName.PARTNER,
                    onSubmit: $options.addGroupAcl
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values($data.groupsAclsSelected), (acl) => {
                        return (_openBlock(), _createBlock(_component_ACL, {
                          key: acl.id,
                          groupId: acl.id,
                          groupName: acl.name,
                          provider: acl.provider,
                          onRemove: $options.removeGroupAcl
                        }, null, 8, ["groupId", "groupName", "provider", "onRemove"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["acls", "listHeader", "listEmptyStateMessage", "withPartners", "onSubmit"])
                ], 64))
              : _createCommentVNode("", true),
            (
          $data.selectedPrivacy === $data.EPrivacyName.PUBLIC &&
          !$options.userIsAdmin &&
          $options.apiHasPendingChangeToPublic
        )
              ? (_openBlock(), _createBlock(_component_MNotification, {
                  key: 4,
                  type: "information"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($data.contents.alertMessageRequestPending), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_3, [
              (
            $data.selectedPrivacy === $data.EPrivacyName.PUBLIC &&
            $options.apiHasPendingChangeToPublic
          )
                ? (_openBlock(), _createBlock(_component_RouterLink, {
                    key: 0,
                    to: $options.publicPolicyPendingRequestsRoute
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_MButton, {
                        theme: "bordered",
                        label: $data.contents.viewYourRequest
                      }, null, 8, ["label"])
                    ]),
                    _: 1
                  }, 8, ["to"]))
                : (_openBlock(), _createBlock(_component_MButton, {
                    key: 1,
                    label: $data.contents.validateAccessPreferences,
                    disabled: !$options.canUpdateApiAccessRestriction,
                    onClick: $options.updateApiAclsAndAccessRestriction
                  }, null, 8, ["label", "disabled", "onClick"]))
            ])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}