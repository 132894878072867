<template>
  <LayerModalForm
    class="add-or-update-documentation-second-step"
    @submit="submit"
  >
    <template #fields>
      <MNotification
        :title="
          documentMethod === EDocumentationPageSource.URL
            ? contents.urlMethod
            : contents.preferUrlMethod
        "
        :type="
          documentMethod === EDocumentationPageSource.URL
            ? 'success'
            : 'warning'
        "
      >
        {{ contents.documentationUpToDate }}
        <template #footer>
          <MLink
            size="s"
            :href="
              convertRouteToHref({
                name: 'docsMain',
                hash: '#add-documentation',
              })
            "
            target="_blank"
          >
            {{ contents.viewDocumentation }}
          </MLink>
        </template>
      </MNotification>
      <DvpField
        v-if="documentationType !== EDocumentationType.REST_API_SPECIFICATIONS"
        :label="contents.documentTitle"
        required
        :errorMessage="form.firstError('title')"
      >
        <MTextInput v-model="form.title" :isInvalid="isInvalidDocTitle" />
      </DvpField>

      <DvpField :label="contents.documentMethod" required>
        <MSelect
          id="select-documentation-method"
          v-model="documentMethod"
          data-cy="select-documentation-method"
          :options="contents.documentationTypes"
        />
      </DvpField>

      <template v-if="documentMethod === EDocumentationPageSource.URL">
        <DvpField
          :label="contents.documentUrl"
          required
          :errorMessage="form.firstError('url')"
        >
          <MTextInput v-model="form.url" :isInvalid="isInvalidDocUrl" />
        </DvpField>
      </template>
      <template v-if="documentMethod === EDocumentationPageSource.CONTENT">
        <DvpField
          :label="contents.documentContent"
          required
          :errorMessage="form.firstError('content')"
        >
          <MTextArea
            v-model="form.content"
            class="add-or-update-documentation-second-step__content-textarea"
            :spellcheck="false"
            :placeholder="`${typeLabel} content`"
            :isInvalid="isInvalidDocContent"
          />
        </DvpField>
      </template>
      <template v-if="documentMethod === EDocumentationPageSource.FILE">
        <MFileUploader
          id="file-uploader-button"
          :label="contents.selectFileToUpload"
          :accept="
            [
              EDocumentationType.CHANGELOG,
              EDocumentationType.MARKDOWN,
            ].includes(documentationType)
              ? '.md'
              : '.json,.yml,.yaml'
          "
          @file-added="filesChange"
        ></MFileUploader>
      </template>
    </template>
    <template #formFooter>
      <MButton
        type="button"
        theme="bordered-neutral"
        :label="contents.cancel"
        @click="$emit('onClose')"
      />
      <MButton
        type="submit"
        :label="contents.submit"
        :disabled="form.errors.length > 0"
      />
    </template>
  </LayerModalForm>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MFileUploader from "@mozaic-ds/vue-3/src/components/fileuploader/MFileUploader.vue";
import MLink from "@mozaic-ds/vue-3/src/components/link/MLink.vue";
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import MSelect from "@mozaic-ds/vue-3/src/components/select/MSelect.vue";
import MTextArea from "@mozaic-ds/vue-3/src/components/textarea/MTextArea.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";

import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalForm from "@/commons/components/form/LayerModalForm.vue";

import { decodeAndFormat } from "@/commons/libs/utils/html";
import { convertRouteToHref } from "@/commons/utils/route-utils";

import {
  EDocumentationPageSource,
  EDocumentationType,
} from "@/commons/domain/models/documentation-page";
import { CreateUpdateDocumentationForm } from "@/commons/forms/create-update-documentation-form";

import {
  NotificationContent,
  NotificationLink,
} from "@/commons/store/modules/notification/types";

import contents from "@/manager/contents/add-or-update-documentation-second-step";
import { getTypeLabel } from "@/manager/contents/documentation-type-items";

export default {
  components: {
    LayerModalForm,
    DvpField,
    MButton,
    MFileUploader,
    MNotification,
    MSelect,
    MTextArea,
    MTextInput,
    MLink,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    pageId: {
      type: String,
    },
    // eslint-disable-next-line vue/require-default-prop
    selectedType: {
      type: Object,
    },
  },
  emits: ["onClose"],
  data() {
    return {
      contents,
      form: CreateUpdateDocumentationForm.create(),
      fileError: "",
      fileInfos: undefined,
      EDocumentationType,
      EDocumentationPageSource,
      documentMethod: EDocumentationPageSource.URL,
    };
  },
  computed: {
    documentationType() {
      if (this.pageId) {
        return this.documentationPage.type;
      } else {
        return this.selectedType?.value;
      }
    },
    typeLabel() {
      return this.documentationType ? getTypeLabel(this.documentationType) : "";
    },
    isInvalidDocTitle() {
      return this.form.firstError("title") != null;
    },
    isInvalidDocUrl() {
      return this.form.firstError("url") != null;
    },
    isInvalidDocContent() {
      return this.form.firstError("content") != null;
    },
    documentationPage() {
      return this.$store.getters["managerDocumentationPage"](this.pageId);
    },
    mockPlatformIsEnabled() {
      return this.$store.getters["config/mockPlatformIsEnabled"];
    },
    getMockPlatformUIUrl() {
      return this.$store.getters["config/getMockPlatformUIUrl"];
    },
  },
  created() {
    if (this.pageId) {
      this.$store.dispatch("loadDocumentationPage", this.pageId);
    }
  },
  async mounted() {
    if (this.pageId) {
      this.documentMethod =
        this.documentationPage.url === ""
          ? EDocumentationPageSource.CONTENT
          : EDocumentationPageSource.URL;

      await this.form.init({
        title: this.documentationPage.title,
        url: this.documentationPage.url,
        content: decodeAndFormat(this.documentationPage.content),
        type: this.documentationPage.type,
        inputMethod: this.documentationPage.url
          ? EDocumentationPageSource.URL
          : EDocumentationPageSource.CONTENT,
      });
    } else {
      let title =
        this.selectedType.value === EDocumentationType.REST_API_SPECIFICATIONS
          ? getTypeLabel(this.selectedType.value)
          : "";

      this.form.init({
        type: this.selectedType.value,
        title,
        inputMethod: EDocumentationPageSource.URL,
      });
    }
  },
  methods: {
    convertRouteToHref,
    filesChange(target) {
      const file = target[0];
      const reader = new FileReader();
      this.fileInfos = file;

      reader.onload = (onLoadEvent) => {
        this.form.content =
          onLoadEvent.target && ((onLoadEvent.target as any).result as string);
        this.form.url = "";
      };
      reader.readAsText(file);
    },
    async submit() {
      if (this.pageId) {
        await this.submitUpdate();
      } else {
        await this.submitCreate();
      }
    },
    async submitCreate() {
      if (!this.form.errors.length) {
        const { title, content, url, inputMethod } = this.form.data();

        const toastShouldBeEnrichedWithMockInformation =
          this.selectedType.value ===
            EDocumentationType.REST_API_SPECIFICATIONS &&
          this.mockPlatformIsEnabled;

        const additionalToastNotificationContent: NotificationContent = {
          message: toastShouldBeEnrichedWithMockInformation
            ? contents.apiDockDeclaredOnMockPlatform
            : undefined,
          link: toastShouldBeEnrichedWithMockInformation
            ? ({
                openInNewTab: true,
                label: contents.mockPlatformLinkLabel,
                href: this.getMockPlatformUIUrl,
              } as NotificationLink)
            : undefined,
        };

        const willCreateDocFromUrl =
          inputMethod === EDocumentationPageSource.URL &&
          url != undefined &&
          url !== "";

        const payload = {
          type: this.selectedType.value,
          title,
          url: willCreateDocFromUrl ? url : undefined,
          content: willCreateDocFromUrl ? undefined : content,
          additionalToastNotificationContent,
        };

        await this.$store.dispatch("createDocumentationPage", payload);
      }
    },
    async submitUpdate() {
      if (!this.form.errors.length) {
        const { title, content, url, type } = this.form.data();

        let payloadCommonPart = {
          pageId: this.pageId,
          type,
          title,
        };

        const payload =
          this.documentMethod === EDocumentationPageSource.URL && url
            ? { ...payloadCommonPart, url } // URL is set and the content hasn't changed, the doc source should be URL
            : { ...payloadCommonPart, content }; // URL is null or the content has changed, the doc source should be CONTENT

        await this.$store.dispatch("updateDocumentationPage", payload);
      }
    },
  },
};
</script>

<style lang="scss">
.add-or-update-documentation-second-step__content-textarea {
  height: 12rem;
}
</style>
