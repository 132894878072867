<template>
  <div class="contract-loading-skeleton">
    <div class="contract-loading-skeleton__contract-card">
      <div class="contract-loading-skeleton__contract-card-container">
        <div class="contract-loading-skeleton__contract-card-info">
          <div class="contract-loading-skeleton__contract-card-title"></div>
          <div class="contract-loading-skeleton__contract-card-title"></div>
        </div>
        <div class="contract-loading-skeleton__contract-card-actions">
          <div
            class="contract-loading-skeleton__contract-card-action-button"
          ></div>
        </div>
      </div>
      <div class="contract-loading-skeleton__contract-card-keys">
        <div class="contract-loading-skeleton__contract-card-key"></div>
        <div class="contract-loading-skeleton__contract-card-key"></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.contract-loading-skeleton {
  &__contract-card {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 12.5rem;
    padding: 1.5rem;
    border: var(--border-default);
    border-radius: var(--m-border-radius-medium);

    &-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &-actions {
      display: flex;
      align-items: center;
    }

    &-keys {
      display: flex;
      flex-direction: column;

      gap: 1rem;
    }

    &-title {
      width: 6.25rem;
      height: 0.9rem;
    }

    &-action-button {
      width: 5rem;
      height: 1.5rem;
    }

    &-key {
      width: 13.5rem;
      height: 0.7rem;
    }
  }

  .contract-loading-skeleton__contract-card-title,
  .contract-loading-skeleton__contract-card-action-button,
  .contract-loading-skeleton__contract-card-key {
    background-color: var(--color-light-grey);
    border-radius: 2rem;

    animation: blink 1.5s infinite;
  }
}
</style>
