<template>
  <Layout class="apis-catalog" :isLoading="false">
    <LayoutSection background="primary" noVerticalPadding fullWidthContent>
      <LayoutContentHeader
        :title="contents.title"
        :description="contents.description"
      />
    </LayoutSection>
    <LayoutSection background="secondary" class="apis-catalog__content">
      <div class="apis-catalog__filters-container">
        <div class="apis-catalog__filters">
          <div class="apis-catalog__filters-on-left-side">
            <CheckboxFilter
              data-cy="only-subscribed-apis-filter"
              queryParamName="onlySubscribed"
              :label="contents.onlySubscribedLabel"
              :queryParamValue="urlQuery.onlySubscribed"
            />

            <CheckboxesListFilter
              data-cy="api-types-filter"
              queryParamName="types"
              :queryParamValue="urlQuery.types"
              :items="apiTypes"
              inline
            />
          </div>
          <div class="apis-catalog__filters-on-right-side">
            <SearchTextFilter
              data-cy="search-filter"
              class="apis-catalog__search-filter"
              queryParamName="term"
              size="s"
              :placeholder="contents.searchPlaceholder"
              :queryParamValue="urlQuery.term"
            />
            <MButton
              data-cy="more-filters-button"
              icon="DisplayFilter24"
              theme="bordered"
              size="s"
              :label="contents.moreFiltersButtonLabel"
              @click="openFilters"
            />
          </div>
        </div>

        <TagsAppliedFilters
          data-cy="filters-taglist"
          :appliedFiltersTagList="appliedFiltersTagList"
          @filterRemoved="removeFilter"
        />
      </div>

      <ListApisCatalog
        class="apis-catalog__list-apis"
        :apis="apiList"
        :isLoading="isLoading"
        :hideProduct="!userIsInternal"
      />

      <QueryParamPagination
        v-if="apisTotalPages"
        :totalPagesNumber="apisTotalPages"
      />
    </LayoutSection>
  </Layout>
</template>

<script lang="ts">
import { ApiCatalogListUrlQuery } from "@/documentation/routes";
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import { PropType } from "vue";

import CheckboxFilter from "@/commons/components/Filters/CheckboxFilter.vue";
import CheckboxesListFilter from "@/commons/components/Filters/CheckboxesListFilter.vue";
import SearchTextFilter from "@/commons/components/Filters/SearchTextFilter.vue";
import TagsAppliedFilters from "@/commons/components/Filters/TagsAppliedFilters.vue";
import Layout from "@/commons/components/Layout.vue";
import LayoutContentHeader from "@/commons/components/LayoutContentHeader.vue";
import LayoutSection from "@/commons/components/LayoutSection.vue";
import ListApisCatalog from "@/commons/components/ListApisCatalog.vue";
import QueryParamPagination from "@/commons/components/QueryParamPagination.vue";
import ModalFiltersApiCatalog from "@/documentation/components/ModalFiltersApiCatalog.vue";

import {
  ACCESS_TYPES_FILTER_ID_PREFIX,
  API_TYPES,
  buildApiCatalogFetchParams,
  getOrderedBuTagsList,
  PRIVACIES_FILTER_ID_PREFIX,
  removeIdFromUrlQuery,
} from "@/commons/utils/filter-utils";
import {
  tagsToMTagListItems,
  valuesUpperCaseToMCheckboxGroupOptions,
  valuesUpperCaseToMTagListItems,
} from "@/commons/utils/mozaic-utils";
import { changeUrlQuery, replaceUrlQuery } from "@/commons/utils/route-utils";

import { Api } from "@/commons/domain/models/api";
import {
  EMTagListType,
  MCheckboxGroupOption,
  MTagListItem,
} from "@/commons/domain/models/mozaic";
import { Product } from "@/commons/domain/models/product";
import { Tag } from "@/commons/domain/models/tag";
import { Zone } from "@/commons/domain/models/zone";
import { FiltersApiCatalogFormData } from "@/commons/forms/filters-api-catalog-form";
import { getProductsByIds } from "@/commons/services/product/product-service";

import contents from "@/documentation/contents/api-list";

const contentsForFiltersModal = require("@/documentation/contents/modal-filters-api-catalog");

export default {
  name: "ApisCatalog",
  components: {
    QueryParamPagination,
    CheckboxFilter,
    Layout,
    LayoutSection,
    LayoutContentHeader,
    ListApisCatalog,
    CheckboxesListFilter,
    MButton,
    SearchTextFilter,
    TagsAppliedFilters,
  },
  props: {
    urlQuery: {
      type: Object as PropType<ApiCatalogListUrlQuery>,
      required: true,
    },
  },
  data() {
    return {
      contents,
      contentsForFiltersModal,
      selectedProducts: [] as Product[],
      isLoadingProducts: false as Boolean,
    };
  },
  computed: {
    isLoading(): boolean {
      return (
        this.$store.getters["isLoadingProperty"]("apis") ||
        this.isLoadingProducts
      );
    },
    userIsInternal(): boolean {
      const user = this.$store.getters["user"];
      return user && user.isInternal;
    },
    userIsLogged(): boolean {
      return this.$store.getters["userIsLogged"];
    },
    apiList(): Api[] {
      return this.$store.getters["apisList"];
    },
    apisTotalPages(): number {
      return this.$store.getters["apisTotalPages"];
    },
    publicationZones(): Zone[] {
      return this.$store.getters["zonesList"];
    },
    businessUnits(): Tag[] {
      return getOrderedBuTagsList(this.$store.getters["buTagsList"]);
    },
    platforms(): Tag[] {
      return this.$store.getters["platformTagsList"];
    },
    apiTypes(): MCheckboxGroupOption[] {
      return valuesUpperCaseToMCheckboxGroupOptions(API_TYPES);
    },
    publicationZoneFromUrlQuery(): Zone | undefined {
      return this.urlQuery.publicationZoneId
        ? this.publicationZones.find(
            (z) => z.id === this.urlQuery.publicationZoneId,
          )
        : undefined;
    },
    publicationZoneTagListItems(): MTagListItem[] {
      return this.publicationZoneFromUrlQuery
        ? [
            {
              id: this.publicationZoneFromUrlQuery.id,
              label: this.publicationZoneFromUrlQuery.name,
              type: EMTagListType.REMOVABLE,
            },
          ]
        : [];
    },
    productsTagListItems(): MTagListItem[] {
      return this.selectedProducts
        ? this.selectedProducts.map((p) => ({
            id: p.id,
            label: p.name,
            type: EMTagListType.REMOVABLE,
          }))
        : [];
    },
    businessUnitsFromUrlQuery(): Tag[] {
      return this.urlQuery.businessUnitsIds
        ? this.businessUnits.filter((p) =>
            this.urlQuery.businessUnitsIds.includes(p.id),
          )
        : [];
    },
    businessUnitsTagListItems(): MTagListItem[] {
      return this.businessUnitsFromUrlQuery
        ? tagsToMTagListItems(
            this.businessUnitsFromUrlQuery,
            EMTagListType.REMOVABLE,
          )
        : [];
    },
    platformsFromUrlQuery(): Tag[] {
      return this.urlQuery.platformsIds
        ? this.platforms.filter((p) =>
            this.urlQuery.platformsIds.includes(p.id),
          )
        : [];
    },
    platformsTagListItems(): MTagListItem[] {
      return this.platformsFromUrlQuery
        ? tagsToMTagListItems(
            this.platformsFromUrlQuery,
            EMTagListType.REMOVABLE,
          )
        : [];
    },
    accessTypesFromUrlQuery(): string[] {
      return this.urlQuery.accessTypes ? this.urlQuery.accessTypes : [];
    },
    accessTypesTagListItems(): MTagListItem[] {
      return this.accessTypesFromUrlQuery
        ? valuesUpperCaseToMTagListItems(
            this.accessTypesFromUrlQuery,
            EMTagListType.REMOVABLE,
            ACCESS_TYPES_FILTER_ID_PREFIX,
          )
        : [];
    },
    privaciesFromUrlQuery(): string[] {
      return this.urlQuery.privacies ? this.urlQuery.privacies : [];
    },
    privaciesTagListItems(): MTagListItem[] {
      return this.privaciesFromUrlQuery
        ? valuesUpperCaseToMTagListItems(
            this.privaciesFromUrlQuery,
            EMTagListType.REMOVABLE,
            PRIVACIES_FILTER_ID_PREFIX,
          )
        : [];
    },
    appliedFiltersTagList(): MTagListItem[] {
      const appliedFiltersTagList = [];
      appliedFiltersTagList.push(
        ...this.productsTagListItems,
        ...this.businessUnitsTagListItems,
        ...this.publicationZoneTagListItems,
        ...this.privaciesTagListItems,
        ...this.accessTypesTagListItems,
        ...this.platformsTagListItems,
      );
      return appliedFiltersTagList;
    },
  },
  watch: {
    async userIsLogged(userIsLogged) {
      if (!userIsLogged) {
        await this.resetFilters();
      }
    },
    async urlQuery(newUrlQuery: ApiCatalogListUrlQuery) {
      await this.$store.dispatch(
        "loadApis",
        buildApiCatalogFetchParams(newUrlQuery),
      );
      await this.updateSelectedProducts(newUrlQuery.productsIds);
    },
  },

  async mounted() {
    /*
      loadApis has to be the first call
      we use the same store properties to display apis
      on API manager and on API catalog
      => if we put something else before
      we can have the wrong list before the APIs has reloaded
    */
    await this.$store.dispatch(
      "loadApis",
      buildApiCatalogFetchParams(this.urlQuery),
    );
    await this.updateSelectedProducts(this.urlQuery.productsIds);
  },
  methods: {
    openFilters() {
      this.$store.commit("openLayerModal", {
        title: contentsForFiltersModal.moreFiltersHeader,
        component: ModalFiltersApiCatalog,
        props: {
          formData: {
            productsIds: [...this.urlQuery.productsIds],
            platformsIds: [...this.urlQuery.platformsIds],
            businessUnitsIds: [...this.urlQuery.businessUnitsIds],
            accessTypes: [...this.urlQuery.accessTypes],
            privacies: [...this.urlQuery.privacies],
            publicationZoneId: this.urlQuery.publicationZoneId,
          },
        },
        listeners: {
          submitted: async (updatedFormData: FiltersApiCatalogFormData) => {
            this.selectedProducts = updatedFormData.products;
            updatedFormData.products = undefined;
            await this.changeUrlQuery(updatedFormData);
          },
        },
      });
    },
    async changeUrlQuery(newUrlQuery: any) {
      await changeUrlQuery({ ...newUrlQuery, page: 1 });
    },
    async resetFilters() {
      await replaceUrlQuery({});
    },
    async removeFilter(filterId: string) {
      const updatedUrlQuery = removeIdFromUrlQuery(filterId, this.urlQuery);
      await this.changeUrlQuery(updatedUrlQuery);
    },
    async updateSelectedProducts(productsIds: string[]) {
      this.isLoadingProducts = true;
      this.selectedProducts = await getProductsByIds(productsIds);
      this.isLoadingProducts = false;
    },
  },
};
</script>

<style lang="scss">
.apis-catalog__content {
  display: flex;
  flex: 1;
}

.apis-catalog__list-apis,
.apis-catalog__filters-container {
  margin-bottom: var(--base-spacing);
}

.apis-catalog__applied-filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: var(--base-spacing);
  gap: 1rem;
}

.apis-catalog__filters-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.apis-catalog__filters {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33rem, 1fr));
  grid-gap: 1.5rem;
}

.apis-catalog__filters-on-left-side {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3rem;
}

.apis-catalog__filters-on-right-side {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}
</style>
