import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "login__adeo-card-text" }
const _hoisted_4 = { class: "login__adeo-card-title" }
const _hoisted_5 = { class: "login__adeo-card-subtitle" }
const _hoisted_6 = { class: "login__adeo-card-partner-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MHeader = _resolveComponent("MHeader")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MLink = _resolveComponent("MLink")!
  const _component_CardContainer = _resolveComponent("CardContainer")!
  const _component_LayoutSection = _resolveComponent("LayoutSection")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Layout, null, {
      default: _withCtx(() => [
        _createVNode(_component_MHeader, {
          title: $data.contents.accessYourAccount,
          items: $options.breadcrumbItems,
          showBreadcrumb: ""
        }, null, 8, ["title", "items"]),
        _createVNode(_component_LayoutSection, {
          class: "login__layout-section",
          fullWidthContent: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CardContainer, { class: "login__adeo-card" }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: $data.adeoLogo,
                  class: "login__adeo-logo"
                }, null, 8, _hoisted_2),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("h4", _hoisted_4, _toDisplayString($data.contents.adeoEmployees), 1),
                  _createElementVNode("p", _hoisted_5, _toDisplayString($data.contents.workingForAdeo), 1),
                  _createVNode(_component_MButton, {
                    class: "login__adeo-card-login-btn",
                    label: $data.contents.loginWithAdeo,
                    href: $options.pingLoginUrl
                  }, null, 8, ["label", "href"]),
                  _createElementVNode("p", _hoisted_6, [
                    _createTextVNode(_toDisplayString($data.contents.externalToAdeo) + " ", 1),
                    _createVNode(_component_MLink, {
                      class: "login__adeo-card-partner-doc-link",
                      size: "s",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.showPartnerLoginHelpModal()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($data.contents.linkToPartnerLoginHelp), 1)
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}