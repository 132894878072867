<script setup lang="ts">
import MBadge from "@mozaic-ds/vue-3/src/components/badge/MBadge.vue";
import MFlag from "@mozaic-ds/vue-3/src/components/flag/MFlag.vue";
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import { computed, markRaw, PropType } from "vue";
import { useStore } from "vuex";

import CardContainer from "@/commons/components/CardContainer.vue";
import Divider from "@/commons/components/Divider.vue";
import DvpToggle from "@/commons/components/DvpToggle.vue";
import DvpTooltip from "@/commons/components/DvpTooltip.vue";
import HelpLink from "@/commons/components/UserDocumentationLinks/HelpLink.vue";
import ActivateSecretSynchronizationModalLayer from "@/dashboard/views/AppDetails/ActivateSecretSynchronizationModalLayer.vue";
import DeactivateSecretSynchronizationModal from "@/dashboard/views/AppDetails/DeactivateSecretSynchronizationModal.vue";
import SecretPath from "@/dashboard/views/AppDetails/SecretPath.vue";

import { Application } from "@/commons/domain/models/application";
import { SecretSynchronization } from "@/commons/domain/models/secret-synchronization";

import contentsOfActivateSecretSynchronizationModalLayer from "@/dashboard/contents/activate-secret-synchronization-modal-layer";
import contentsDeactivateSecretSync from "@/dashboard/contents/deactivate-secret-synchronization-modal";
import contents from "@/dashboard/contents/secret-synchronization-card";

const props = defineProps({
  application: {
    type: Object as PropType<Application>,
    required: true,
  },
});

const store = useStore();

const applicationSecretData = computed((): SecretSynchronization => {
  return props.application.secretSynchronization;
});

const secretManager = computed((): string => {
  return store.getters["config/secretSynchronizationManager"];
});

const applicationSecretSyncIsEnabled = computed((): boolean => {
  return applicationSecretData.value?.isEnabled || false;
});

const secretPublicationIsActivated = computed((): boolean => {
  return store.getters["config/secretPublicationIsActivated"];
});

const userHasWriteAccessOnApplication = computed((): boolean => {
  return store.getters["userHasWriteAccessOnCurrentApplication"];
});

const secretSyncIsInBetaStage = computed((): boolean => {
  return store.getters["config/secretSyncFeatureIsInBetaStage"];
});

const secretAccessManagementUrl = computed((): string => {
  return applicationSecretData.value?.secretAccessManagementUrl;
});

const canActivateSecretPublication = computed((): boolean => {
  return (
    secretPublicationIsActivated.value &&
    applicationSecretSyncIsEnabled.value == false &&
    props.application.forTesting == false
  );
});

const canDeactivateSecretPublication = computed((): boolean => {
  return (
    applicationSecretSyncIsEnabled.value && secretPublicationIsActivated.value
  );
});

const unableActivateTooltipMessage = computed((): string => {
  if (!secretPublicationIsActivated.value) {
    // default message that feature is disabled
    return contents.disabledSecretSyncFeatureTooltipText(secretManager.value);
  } else if (props.application.forTesting) {
    // sync is enabled, but feature can not be activated on testing app
    return contents.canNotActivateOnTestingApp;
  }
  return undefined;
});

const disabledToggle = computed((): boolean => {
  return (
    !userHasWriteAccessOnApplication.value ||
    (!canActivateSecretPublication.value &&
      !canDeactivateSecretPublication.value)
  );
});

const onToggleClick = () => {
  if (canActivateSecretPublication.value) {
    openActivateSecretSynchronizationModal();
  } else if (applicationSecretSyncIsEnabled.value) {
    openDeactivateSecretSynchronizationModal();
  }
};

const openActivateSecretSynchronizationModal = () => {
  store.commit("openLayerModal", {
    title: contentsOfActivateSecretSynchronizationModalLayer.modalTitle,
    component: markRaw(ActivateSecretSynchronizationModalLayer),
    props: {
      application: props.application,
    },
  });
};

const openDeactivateSecretSynchronizationModal = () => {
  store.commit("openLayerModal", {
    title: contentsDeactivateSecretSync.modalTitle,
    component: markRaw(DeactivateSecretSynchronizationModal),
    props: {
      application: props.application,
      secretManager: secretManager.value,
      secretPath: applicationSecretData.value?.secretDisplayPath,
      secretUrl: applicationSecretData.value?.secretUrl,
    },
  });
};
</script>

<template>
  <CardContainer class="secret-synchronization-card">
    <div class="secret-synchronization-card__header">
      <div
        v-if="secretSyncIsInBetaStage"
        class="secret-synchronization-card__beta-version"
      >
        <MFlag :label="contents.betaVersionLabel" theme="solid-danger" />
      </div>
      <div class="secret-synchronization-card__status-badge">
        <MBadge v-if="applicationSecretSyncIsEnabled" type="success">
          {{ contents.enabledBadgeLabel }}</MBadge
        >
        <MBadge v-else type="neutral">
          {{ contents.disabledBadgeLabel }}</MBadge
        >
      </div>
    </div>

    <div class="secret-synchronization-card__container">
      <h4 class="secret-synchronization-card__title">
        {{ contents.title(secretManager) }}
      </h4>

      <div class="secret-synchronization-card__content">
        <MNotification v-if="secretSyncIsInBetaStage" type="warning">{{
          contents.betaVersionMessage
        }}</MNotification>

        <span>{{ contents.subtitle(secretManager) }}</span>
      </div>

      <div class="secret-synchronization-card__toggle-container">
        <span>{{ contents.activateToggleLabel }}</span>

        <DvpTooltip
          :disabled="unableActivateTooltipMessage == undefined"
          :text="unableActivateTooltipMessage"
        >
          <DvpToggle
            data-cy="activate-secret-synchronization-toggle"
            :checked="applicationSecretSyncIsEnabled"
            :disabled="disabledToggle"
            @click="onToggleClick"
          />
        </DvpTooltip>
      </div>

      <HelpLink
        v-if="secretAccessManagementUrl"
        class="secret-synchronization-card__manage-access-link"
        theme="primary"
        :href="secretAccessManagementUrl"
        :htmlContent="contents.shortcutToManageRights(secretManager)"
        openInNewTab
      />
    </div>

    <div
      v-if="applicationSecretSyncIsEnabled"
      class="secret-synchronization-card__secret-data"
    >
      <Divider />

      <SecretPath
        :secretPath="applicationSecretData?.secretDisplayPath"
        :secretUrl="applicationSecretData?.secretUrl"
        :copyContent="applicationSecretData?.secretDisplayPath"
      />
    </div>
  </CardContainer>
</template>

<style lang="scss">
.secret-synchronization-card {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.secret-synchronization-card__header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
}

.secret-synchronization-card__container {
  display: flex;
  flex-direction: column;
  gap: var(--base-spacing);
}

.secret-synchronization-card__content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.secret-synchronization-card__toggle-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 3.5rem;

  padding: 0 1.5rem;

  background-color: var(--color-background-secondary-light);
  border-radius: var(--m-border-radius-medium);
}

.secret-synchronization-card__title {
  margin: 0;
}

.secret-synchronization-card__secret-data {
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-top: 0.5rem;
  gap: 1rem;
}

.secret-synchronization-card__secret-path {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.secret-synchronization-card__manage-access-link {
  align-self: flex-start;
}
</style>
