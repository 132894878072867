import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "my-apps__filters-container" }
const _hoisted_2 = { class: "my-apps__filters" }
const _hoisted_3 = { class: "my-apps__filters-on-left-side" }
const _hoisted_4 = { class: "my-apps__filters-on-right-side" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MyAppsHeading = _resolveComponent("MyAppsHeading")!
  const _component_LayoutSection = _resolveComponent("LayoutSection")!
  const _component_CheckboxFilter = _resolveComponent("CheckboxFilter")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_SearchTextFilter = _resolveComponent("SearchTextFilter")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_TagsAppliedFilters = _resolveComponent("TagsAppliedFilters")!
  const _component_AppList = _resolveComponent("AppList")!
  const _component_QueryParamPagination = _resolveComponent("QueryParamPagination")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, {
    class: "my-apps",
    isLoading: false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_LayoutSection, {
        background: "primary",
        noVerticalPadding: "",
        fullWidthContent: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MyAppsHeading)
        ]),
        _: 1
      }),
      _createVNode(_component_LayoutSection, {
        background: "secondary",
        class: "my-apps__content"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                ($options.userIsAdmin)
                  ? (_openBlock(), _createBlock(_component_CheckboxFilter, {
                      key: 0,
                      "data-cy": "show-all-filter",
                      queryParamName: "showAllApps",
                      label: $data.contents.showAllAppsLabel,
                      queryParamValue: $props.urlQuery.showAllApps
                    }, null, 8, ["label", "queryParamValue"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_RouterLink, {
                  "data-cy": "consult-all-subscribed-apis-link",
                  class: "my-apps__link-to-only-subscribed-apis",
                  to: {
                name: 'apiDocumentation',
                query: { onlySubscribed: 'true' },
              }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($data.contents.onlySubscribedLabelLink), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_SearchTextFilter, {
                  class: "my-apps__search-filter",
                  "data-cy": "search-filter",
                  placeholder: $data.contents.searchLabel,
                  queryParamName: "term",
                  queryParamValue: $props.urlQuery.term,
                  size: "s"
                }, null, 8, ["placeholder", "queryParamValue"]),
                _createVNode(_component_MButton, {
                  "data-cy": "more-filters-button",
                  icon: "DisplayFilter24",
                  theme: "bordered",
                  size: "s",
                  label: $data.contents.moreFiltersButtonLabel,
                  onClick: $options.openFilters
                }, null, 8, ["label", "onClick"]),
                _createVNode(_component_MButton, {
                  "data-cy": "create-app-button",
                  class: "my-apps-heading__create-app-button",
                  label: $data.contents.createNewApp,
                  disabled: $options.hasReachedApplicationLimits,
                  icon: "ControlCircleMore24",
                  size: "s",
                  onClick: $options.createApp
                }, null, 8, ["label", "disabled", "onClick"])
              ])
            ]),
            _createVNode(_component_TagsAppliedFilters, {
              "data-cy": "filters-taglist",
              appliedFiltersTagList: $options.appliedFiltersTagList,
              onFilterRemoved: $options.removeFilter
            }, null, 8, ["appliedFiltersTagList", "onFilterRemoved"])
          ]),
          _createVNode(_component_AppList, {
            class: "my-apps__app-list",
            applications: $options.applications,
            isLoading: $options.isLoading
          }, null, 8, ["applications", "isLoading"]),
          ($options.applicationsTotalPages)
            ? (_openBlock(), _createBlock(_component_QueryParamPagination, {
                key: 0,
                totalPagesNumber: $options.applicationsTotalPages
              }, null, 8, ["totalPagesNumber"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}