<template>
  <div class="layout">
    <div
      :class="[
        'layout__navbars',
        { 'layout__navbars--highlighted': !isBegginingOfScreen },
        navbarsElementClass,
      ]"
    >
      <TopNavbar />
      <slot name="secondaryNavbar" />
    </div>

    <OverlayLoader
      v-if="isLoading"
      class="layout__overlay-loader"
      :message="loadingMessage"
    />

    <div v-else class="layout__wrapper">
      <div
        v-if="!hideSideMenu && $slots.sideMenu != undefined"
        class="layout__side-menu"
      >
        <div
          v-if="$slots.sideMenuHeader != undefined"
          class="layout__side-menu-header"
        >
          <slot name="sideMenuHeader" />
        </div>
        <div class="layout__side-menu-content">
          <slot name="sideMenu" />
        </div>
      </div>
      <div class="layout__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import OverlayLoader from "@/commons/components/OverlayLoader.vue";
import TopNavbar from "@/commons/components/TopNavbar.vue";

import {
  navbarsElementClass,
  setCurrentNavbarsHeightCssValue,
} from "@/commons/utils/navbars-utils";

export default {
  components: {
    TopNavbar,
    OverlayLoader,
  },
  props: {
    // For a custom loading state, instead of showing the loader for any load of the store
    isLoading: {
      type: Boolean,
      default: undefined,
    },
    loadingMessage: {
      type: String,
      default: "",
    },
    hideSideMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isBegginingOfScreen: true,
      navbarsElementClass,
    };
  },
  computed: {
    showLoader(): boolean {
      return this.isLoading != undefined
        ? this.isLoading
        : this.isLoadingAnything;
    },
    isLoadingAnything(): boolean {
      return this.$store.getters.isLoading;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        const { openModal, openLayerModal } = to.meta;

        if (openModal) {
          this.$store.commit("openModal", openModal);
        }
        if (openLayerModal) {
          this.$store.commit("openLayerModal", openLayerModal);
        }
      },
    },
  },
  mounted() {
    this.updateIsBegginingOfScreen();
    window.addEventListener("wheel", this.updateIsBegginingOfScreen);
    window.addEventListener("scroll", this.updateIsBegginingOfScreen);
    this.updateCurrentNavbarsHeightCssValue();
  },
  unmounted() {
    window.removeEventListener("wheel", this.updateIsBegginingOfScreen);
    window.removeEventListener("scroll", this.updateIsBegginingOfScreen);
  },
  methods: {
    updateIsBegginingOfScreen() {
      this.isBegginingOfScreen = window.scrollY === 0;
    },
    updateCurrentNavbarsHeightCssValue() {
      // $slots is not reactive and a computed will not be properly updated after the 'created' step so it's better to not use computed for $slots
      const newCssValue =
        this.$slots.secondaryNavbar != undefined
          ? "calc(var(--navbar-height) + var(--secondary-navbar-height))"
          : "var(--navbar-height)";
      setCurrentNavbarsHeightCssValue(newCssValue);
    },
  },
};
</script>

<style lang="scss">
.layout {
  position: relative;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.layout__overlay-loader {
  position: absolute;
  top: calc(50% - (10rem / 2));
  left: calc(50% - (10rem / 2));
  z-index: var(--z-index-foreground);
}

.layout__navbars {
  position: sticky;
  top: 0;
  z-index: var(--z-index-foreground);
  width: 100%;

  background-color: var(--color-white);
}

.layout__navbars--highlighted {
  box-shadow: var(--shadow-default);
}

.layout__wrapper {
  position: relative;
  z-index: var(--z-index-middleground);

  display: flex;

  flex: 1;
  flex-direction: row;
}

.layout__side-menu {
  position: sticky;
  top: var(--current-navbars-height);
  z-index: var(--z-index-foreground);

  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--current-navbars-height));

  background-color: var(--color-background-primary);
}

.layout__content {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.layout__side-menu-header {
  height: var(--layout-side-menu-header-height);
  background-color: var(--color-white);
  border-right: var(--border-default);
  border-bottom: var(--border-default);
}

.layout__side-menu-content {
  overflow: auto;
}
</style>
