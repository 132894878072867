import { FrontendConfig } from "@/commons/domain/models/frontendConfig.type";
import { InitConfig } from "@/commons/domain/models/init-config";
import { EnvDto } from "@/commons/dtos/env.dto";

import { State } from "./types";

export function setFrontendConfig(
  state: State,
  frontendConfig: Partial<FrontendConfig>,
) {
  state.frontendConfig = frontendConfig;
}

export function setEnvironmentsConfig(state: State, envDto: EnvDto) {
  state.deployments = envDto.deployments;
}

export function setInitConfig(state: State, initConfig: InitConfig) {
  state.environment = initConfig.environment;
  state.validationRules = initConfig.validationRules;
  state.secretSynchronizationConfig = initConfig.secretSynchronization;
  state.timeZonesConfig = initConfig.timeZonesConfig;
  state.mockPlatformConfig = initConfig.mockPlatformConfig;
}

export default {
  setFrontendConfig,
  setEnvironmentsConfig,
  setInitConfig,
};
