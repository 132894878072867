<template>
  <div class="use-case-list-features">
    <div class="use-case-list-features__header">
      {{ contents.listFeaturesHeader }}
    </div>
    <ul>
      <li
        v-for="(feature, index) in features"
        :key="`use-case-list-features-feature-${feature.name}`"
        class="use-case-list-features__feature"
      >
        <a
          class="use-case-list-features__feature-content"
          @click="scrollToAnchor(`#use-case-feature-${feature.name}`, true)"
        >
          <span class="use-case-list-features__number">{{
            pad(index + 1)
          }}</span>
          <span class="use-case-list-features__title">{{ feature.title }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { scrollToAnchor } from "@/commons/libs/scroll-to-anchor";

import contents from "@/showcase/contents/use-case-list-features";

defineProps({
  features: {
    type: Array,
    default: () => [],
  },
});

const pad = (num) => {
  let str = num.toString();
  while (str.length < 2) str = "0" + str;
  return str;
};
</script>

<style lang="scss">
.use-case-list-features__header {
  @include set-text-s;

  margin: 0 0 3rem;
  font-weight: 700;

  color: var(--color-white);

  text-transform: uppercase;
}

.use-case-list-features__feature {
  margin: 0 0 2.5rem;
  list-style-type: none;
  border-bottom: 1px solid var(--color-white);
}

.use-case-list-features__feature-content {
  display: flex;
  align-items: center;

  height: 4rem;
  overflow: hidden;

  color: var(--color-white);

  text-decoration: none;
  cursor: pointer;
}

.use-case-list-features__number {
  width: 5rem;

  margin: 0 2rem 0 0;

  font-size: 3.5rem;
  font-weight: var(--weight-light);
  line-height: 1;
  text-align: center;
  letter-spacing: 0.3rem;
  opacity: 0.45;
}

.use-case-list-features__title {
  @include set-text-l;
  font-weight: 700;
}
</style>
