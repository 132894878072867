<template>
  <Layout class="my-apps" :isLoading="false">
    <LayoutSection background="primary" noVerticalPadding fullWidthContent>
      <MyAppsHeading />
    </LayoutSection>
    <LayoutSection background="secondary" class="my-apps__content">
      <div class="my-apps__filters-container">
        <div class="my-apps__filters">
          <div class="my-apps__filters-on-left-side">
            <CheckboxFilter
              v-if="userIsAdmin"
              data-cy="show-all-filter"
              queryParamName="showAllApps"
              :label="contents.showAllAppsLabel"
              :queryParamValue="urlQuery.showAllApps"
            />
            <RouterLink
              data-cy="consult-all-subscribed-apis-link"
              class="my-apps__link-to-only-subscribed-apis"
              :to="{
                name: 'apiDocumentation',
                query: { onlySubscribed: 'true' },
              }"
            >
              {{ contents.onlySubscribedLabelLink }}
            </RouterLink>
          </div>
          <div class="my-apps__filters-on-right-side">
            <SearchTextFilter
              class="my-apps__search-filter"
              data-cy="search-filter"
              :placeholder="contents.searchLabel"
              queryParamName="term"
              :queryParamValue="urlQuery.term"
              size="s"
            />
            <MButton
              data-cy="more-filters-button"
              icon="DisplayFilter24"
              theme="bordered"
              size="s"
              :label="contents.moreFiltersButtonLabel"
              @click="openFilters"
            />
            <MButton
              data-cy="create-app-button"
              class="my-apps-heading__create-app-button"
              :label="contents.createNewApp"
              :disabled="hasReachedApplicationLimits"
              icon="ControlCircleMore24"
              size="s"
              @click="createApp"
            />
          </div>
        </div>
        <TagsAppliedFilters
          data-cy="filters-taglist"
          :appliedFiltersTagList="appliedFiltersTagList"
          @filterRemoved="removeFilter"
        />
      </div>
      <AppList
        class="my-apps__app-list"
        :applications="applications"
        :isLoading="isLoading"
      />
      <QueryParamPagination
        v-if="applicationsTotalPages"
        :totalPagesNumber="applicationsTotalPages"
      />
    </LayoutSection>
  </Layout>
</template>

<script lang="ts">
import { AppsUrlQuery } from "@/dashboard/routes";
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import { markRaw, PropType } from "vue";

import CheckboxFilter from "@/commons/components/Filters/CheckboxFilter.vue";
import SearchTextFilter from "@/commons/components/Filters/SearchTextFilter.vue";
import TagsAppliedFilters from "@/commons/components/Filters/TagsAppliedFilters.vue";
import Layout from "@/commons/components/Layout.vue";
import LayoutSection from "@/commons/components/LayoutSection.vue";
import QueryParamPagination from "@/commons/components/QueryParamPagination.vue";
import CreateApp from "@/dashboard/components/CreateApp.vue";
import ModalFiltersMyApps from "@/dashboard/components/ModalFiltersMyApps.vue";
import MyAppsHeading from "@/dashboard/views/AppList/MyAppsHeading.vue";

import AppList from "./AppList.vue";

import {
  buildMyAppsFetchParams,
  removeIdFromUrlQuery,
} from "@/commons/utils/filter-utils";
import { valuesToMTagListItems } from "@/commons/utils/mozaic-utils";
import { changeUrlQuery } from "@/commons/utils/route-utils";

import { Api } from "@/commons/domain/models/api";
import { EMTagListType, MTagListItem } from "@/commons/domain/models/mozaic";
import { Product } from "@/commons/domain/models/product";
import { CurrentUser } from "@/commons/domain/models/user";
import { FiltersMyAppsFormData } from "@/commons/forms/filters-my-apps-form";
import { getApisByIds } from "@/commons/services/api/api-service";
import { getProductsByIds } from "@/commons/services/product/product-service";

import createAppModalContents from "@/dashboard/contents/create-or-update-app";
import contents from "@/dashboard/contents/my-apps";

const contentsForMyAppsFiltersModal = require("@/dashboard/contents/modal-filters-my-apps");

export default {
  components: {
    Layout,
    MyAppsHeading,
    AppList,
    LayoutSection,
    CheckboxFilter,
    SearchTextFilter,
    MButton,
    QueryParamPagination,
    TagsAppliedFilters,
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters["isModalOpen"]) {
      this.$store.commit("closeModal");
    }
    next();
  },
  props: {
    urlQuery: {
      type: Object as PropType<AppsUrlQuery>,
      required: true,
    },
  },
  data() {
    return {
      contents,
      selectedProducts: [] as Product[],
      selectedUsedApis: [] as Api[],
    };
  },
  computed: {
    applications() {
      return this.$store.getters["applicationsList"];
    },
    applicationsTotalPages() {
      return this.$store.getters["applicationsListTotalPages"];
    },
    isLoading() {
      return this.$store.getters["isLoadingProperty"]("applications");
    },
    userIsAdmin() {
      return this.$store.getters["userIsAdmin"];
    },
    user(): CurrentUser {
      return this.$store.getters["user"];
    },
    hasReachedApplicationLimits(): boolean {
      return Boolean(
        this.user &&
          this.user.limits.maxApplications <= this.user.limits.applicationCount,
      );
    },
    usedApisFromUrlQuery(): Api[] {
      return this.urlQuery.usedApisIds ? this.selectedUsedApis : [];
    },
    usedApisTagListItems(): MTagListItem[] {
      return this.usedApisFromUrlQuery
        ? this.usedApisFromUrlQuery.map((p) => ({
            id: p.id,
            label: p.name,
            type: EMTagListType.REMOVABLE,
          }))
        : [];
    },
    productsTagListItems(): MTagListItem[] {
      return this.selectedProducts
        ? this.selectedProducts.map((p) => ({
            id: p.id,
            label: p.name,
            type: EMTagListType.REMOVABLE,
          }))
        : [];
    },
    subscriptionStatusesFromUrlQuery(): string[] {
      return this.urlQuery.subscriptionStatuses || [];
    },
    subscriptionStatusesTagListItems(): MTagListItem[] {
      return this.subscriptionStatusesFromUrlQuery
        ? valuesToMTagListItems(
            this.subscriptionStatusesFromUrlQuery,
            EMTagListType.REMOVABLE,
          )
        : [];
    },
    apisStatusesFromUrlQuery(): string[] {
      return this.urlQuery.apisStatuses || [];
    },
    apisStatusesTagListItems(): MTagListItem[] {
      return this.apisStatusesFromUrlQuery
        ? valuesToMTagListItems(
            this.apisStatusesFromUrlQuery,
            EMTagListType.REMOVABLE,
          )
        : [];
    },
    appliedFiltersTagList(): MTagListItem[] {
      const appliedFiltersTagList = [];
      appliedFiltersTagList.push(
        ...this.usedApisTagListItems,
        ...this.productsTagListItems,
        ...this.subscriptionStatusesTagListItems,
        ...this.apisStatusesTagListItems,
      );
      return appliedFiltersTagList;
    },
  },
  watch: {
    async urlQuery(newUrlQuery: AppsUrlQuery, previousUrlQuery: AppsUrlQuery) {
      const switchOnShowAllApps =
        previousUrlQuery.showAllApps !== newUrlQuery.showAllApps;

      if (switchOnShowAllApps) {
        await this.removeUnselectableUsedApisFromQuery(
          newUrlQuery.usedApisIds,
          newUrlQuery.showAllApps,
        );
      } else {
        await this.updateSelectedUsedApis(
          newUrlQuery.usedApisIds,
          !newUrlQuery.showAllApps,
        );
      }
      await this.updateSelectedProducts(newUrlQuery.productsIds);

      await this.loadApplications(newUrlQuery);
    },
  },
  async mounted() {
    await this.loadApplications(this.urlQuery);
    if (this.urlQuery.productsIds?.length > 0) {
      await this.updateSelectedProducts(this.urlQuery.productsIds);
    }
    if (this.urlQuery.usedApisIds?.length > 0) {
      await this.updateSelectedUsedApis(
        this.urlQuery.usedApisIds,
        !this.urlQuery.showAllApps,
      );
    }
  },
  methods: {
    createApp() {
      this.$store.commit("openLayerModal", {
        title: createAppModalContents.modalTitle,
        component: markRaw(CreateApp),
      });
    },
    async loadApplications(query: AppsUrlQuery) {
      await this.$store.dispatch(
        "loadApplications",
        buildMyAppsFetchParams(query),
      );
    },
    openFilters() {
      this.$store.commit("openLayerModal", {
        title: contentsForMyAppsFiltersModal.moreFiltersHeader,
        component: ModalFiltersMyApps,
        props: {
          onlySubscribed: !this.urlQuery.showAllApps,
          formData: {
            usedApisIds: [...this.urlQuery.usedApisIds],
            productsIds: [...this.urlQuery.productsIds],
            subscriptionStatuses: [...this.urlQuery.subscriptionStatuses],
            apisStatuses: [...this.urlQuery.apisStatuses],
          },
        },
        listeners: {
          submitted: async (updatedFormData: FiltersMyAppsFormData) => {
            await this.changeUrlQuery(updatedFormData);
          },
        },
      });
    },
    async changeUrlQuery(newUrlQuery: any) {
      await changeUrlQuery({ ...newUrlQuery, page: 1 });
    },
    async removeFilter(filterId: string) {
      const updatedUrlQuery = removeIdFromUrlQuery(filterId, this.urlQuery);
      await this.changeUrlQuery(updatedUrlQuery);
    },
    async updateSelectedProducts(productsIds: string[]) {
      this.selectedProducts = await getProductsByIds(productsIds);
    },
    async updateSelectedUsedApis(usedApisIds: string[], onlySubscribed) {
      this.selectedUsedApis = await getApisByIds(usedApisIds, onlySubscribed);
    },
    async removeUnselectableUsedApisFromQuery(
      usedApisIds: string[],
      showAllApps: boolean,
    ) {
      // update selectedUsedApis according showAllApps and onlySubscribed parameters
      await this.updateSelectedUsedApis(usedApisIds, !showAllApps);

      const admissibleSelectedIds = this.selectedUsedApis.map(
        (item) => item.id,
      );

      await changeUrlQuery({
        usedApisIds: admissibleSelectedIds,
      });
    },
  },
};
</script>

<style lang="scss">
.my-apps__content {
  display: flex;
  flex: 1;
}
.my-apps__app-list,
.my-apps__filters-container {
  margin-bottom: var(--base-spacing);
}
.my-apps__filters-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.my-apps__filters {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 1.5rem;
}
.my-apps__filters-on-left-side {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
}
.my-apps__filters-on-right-side {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}
</style>
