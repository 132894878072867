<template>
  <div class="tos-content">
    <div v-html="termsOfUse" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";

import getTermsOfUse from "@/commons/libs/get-terms-of-use";

const store = useStore();

const locationSettings = computed(() => {
  return store.getters["config/locationSettings"];
});

const termsOfUse = computed(() => {
  return getTermsOfUse(locationSettings.value);
});
</script>

<style lang="scss">
.tos-content {
  display: flex;

  width: 42rem;
  margin: 0 auto;
}
</style>
