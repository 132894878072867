<template>
  <div class="home-main">
    <div class="home-main__message">
      <!--eslint-disable vue/no-v-html-->
      <TitleAnimated
        :emphaseWords="contents.emphaseWords"
        v-html="contents.title"
      />
      <p class="home-main__description">{{ contents.description }}</p>
      <div class="home-main__buttons">
        <DvpButton
          theme="bordered"
          :label="contents.useCasesButtonLabel"
          @click="() => scrollToAnchor('#home-use-cases', true)"
        />
        <DvpButton
          theme="bordered-neutral"
          :label="contents.tryItButtonLabel"
          @click="() => scrollToAnchor('#home-try-it', true)"
        />
      </div>
    </div>
    <div class="home-main__picture">
      <img
        class="home-main__picture-image"
        :src="phoneImg"
        alt="Smartphone with online maps"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";

import DvpButton from "@/commons/components/Action/DvpButton.vue";
import TitleAnimated from "@/commons/components/TitleAnimated.vue";

import { scrollToAnchor } from "@/commons/libs/scroll-to-anchor";

import content from "@/showcase/contents/home-main";

const phoneImg = require("@/commons/assets/images/phone.svg");

const store = useStore();

const locationSettings = computed(() => {
  return store.getters["config/locationSettings"];
});

const contents = computed(() => {
  return content[locationSettings.value.name];
});
</script>

<style lang="scss">
@import "~@/commons/assets/styles/lib/breakpoints-mixin";

.home-main {
  display: flex;
  justify-content: space-between;

  @include respond-to("mobile-small") {
    flex-direction: column;
    gap: var(--base-spacing-l);
  }
}

.home-main__message {
  position: relative;

  display: flex;
  flex: 0.5;
  flex-direction: column;

  justify-content: center;

  height: 100%;

  gap: var(--base-spacing-l);

  @include respond-to("mobile-small") {
    gap: var(--base-spacing);
  }
}

.home-main__description {
  position: relative;

  width: 75%;

  font-weight: 300;

  @include set-text-l;
}

.home-main__buttons {
  z-index: var(--z-index-foreground);
  display: flex;

  gap: var(--base-spacing);
}

.home-main__picture {
  position: relative;

  display: flex;

  flex: 0.5;
  justify-content: center;
}

.home-main__picture-image {
  min-width: 35%;
  max-width: 17rem;
}
</style>
