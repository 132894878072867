<template>
  <Layout class="page-not-found">
    <LayoutSection fullWidthContent>
      <div class="page-not-found__content">
        <slot name="image" />
        <h1>{{ title }}</h1>
        <DvpButton
          v-if="userIsLogged"
          class="page-not-found__redirect-button"
          :label="contents.backDashboard"
          :href="convertRouteToHref({ name: 'dashboard' })"
        />

        <DvpButton
          v-else
          class="page-not-found__redirect-button"
          :label="contents.backHome"
          :href="convertRouteToHref({ name: 'home' })"
        />
      </div>
    </LayoutSection>
  </Layout>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";

import DvpButton from "@/commons/components/Action/DvpButton.vue";
import Layout from "@/commons/components/Layout.vue";
import LayoutSection from "@/commons/components/LayoutSection.vue";

import { convertRouteToHref } from "@/commons/utils/route-utils";

import contents from "@/commons/contents/page-not-accessible";

defineProps({
  title: {
    type: String,
    required: true,
  },
});

const store = useStore();

const userIsLogged = computed((): boolean => {
  return store.getters["userIsLogged"];
});
</script>

<style lang="scss">
.page-not-found {
  --responsive-image-max-width: 70rem;
}

.page-not-found__content {
  display: flex;
  flex-direction: column;

  align-items: center;

  gap: var(--base-spacing);
}

.page-not-found__redirect-button {
  width: 15rem;
}
</style>
