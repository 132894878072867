<template>
  <div class="quota-api-settings">
    <h3>{{ contents.title }}</h3>
    <CardContainer>
      <TitleWithNumber
        :number="contents.numberOne"
        :title="contents.chooseQuotaType"
      />

      <div class="quota-api-settings__quota-type-options-container">
        <QuotaType
          v-for="(option, index) in quotaTypeOptions"
          :key="index"
          :icon="option.icon"
          :title="option.title"
          :description="option.description"
          :isActive="option.value === selectedQuotaType"
          @click="onClickOnQuotaTypeOption(option.value)"
        />
      </div>

      <div
        v-if="!isSavingQuotaTypeChange"
        class="quota-api-settings__rate-limits-section"
      >
        <!--   API quota   -->
        <div v-if="selectedQuotaType === EQuotaType.API_QUOTA">
          <TitleWithNumber
            :number="contents.numberTwo"
            :title="contents.defineQuotaYouNeed"
            :description="contents.apiQuotaExplanation"
          />
          <QuotaRateLimitDisplay
            v-if="rateLimits && rateLimits.global"
            :rateLimit="rateLimits.global"
            @editRateLimit="onEditApiRateLimit"
            @activationChange="onApiRateLimitActivationChange"
          />
          <div v-else class="quota-api-settings__add-new-quota">
            <MButton
              :label="contents.addQuotaForApi"
              icon="PublishEdit48"
              iconPosition="left"
              @click="onClickOnAddApiRateLimit"
            />
          </div>
        </div>

        <!-- Consumers quota -->
        <div v-else>
          <TitleWithNumber
            :number="contents.numberTwo"
            :title="contents.defineQuotaYouNeed"
            :description="contents.consumerQuotaExplanation"
          />
          <QuotaRateLimitDisplay
            v-if="rateLimitsForNewContracts && rateLimitsForNewContracts.global"
            :rateLimit="rateLimitsForNewContracts.global"
            @editRateLimit="onEditRateLimitForNewContracts"
            @activationChange="onRateLimitForNewContractsActivationChange"
          />
          <div v-else class="quota-api-settings__add-new-quota">
            <MButton
              :label="contents.addQuotaForNewConsumer"
              icon="PublishEdit48"
              iconPosition="left"
              @click="onClickOnAddRateLimitForNewContracts"
            />
          </div>

          <QuotaConsumers
            :allowedWindowLengthUnits="allowedWindowLengthUnits"
          />
        </div>
      </div>
    </CardContainer>
  </div>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import { markRaw } from "vue";

import CardContainer from "@/commons/components/CardContainer.vue";
import TitleWithNumber from "@/commons/components/TitleWithNumber.vue";
import QuotaConsumers from "@/manager/views/ApiDetailQuotas/QuotaConsumers.vue";
import QuotaRateLimitDisplay from "@/manager/views/ApiDetailQuotas/QuotaRateLimitDisplay.vue";
import QuotaRateLimitEditionModal from "@/manager/views/ApiDetailQuotas/QuotaRateLimitEditionModal.vue";
import QuotaType from "@/manager/views/ApiDetailQuotas/QuotaType.vue";
import QuotaTypeChangeModal from "@/manager/views/ApiDetailQuotas/QuotaTypeChangeModal.vue";

import {
  buildDefaultRateLimit,
  getAllowedWindowLengthUnits,
} from "@/commons/utils/quota-utils";

import { Api } from "@/commons/domain/models/api";
import {
  EWindowLengthUnit,
  RateLimit,
} from "@/commons/domain/models/rate-limit";
import { RateLimits } from "@/commons/domain/models/rate-limits";

import { EQuotaType } from "@/commons/store/types";

import contents from "@/manager/contents/quota-api-settings.js";
import contentsForRateLimitEditionModal from "@/manager/contents/quota-rate-limit-edition-modal";
import contentsForQuotaTypeChangeModal from "@/manager/contents/quota-type-change-modal.js";

interface QuotaTypeOption {
  title: string;
  icon: any;
  description: string;
  value: EQuotaType;
}

export default {
  components: {
    CardContainer,
    QuotaConsumers,
    QuotaType,
    MButton,
    QuotaRateLimitDisplay,
    TitleWithNumber,
  },
  data() {
    return {
      contents,
      EQuotaType,
      selectedQuotaType: EQuotaType.API_QUOTA,
    };
  },
  computed: {
    quotaTypeOptions(): QuotaTypeOption[] {
      return [
        {
          title: contents.apiQuotaOptionLabel,
          icon: require("@/commons/assets/icons/icon-api.svg"),
          description: contents.apiQuotaOptionDescription,
          value: EQuotaType.API_QUOTA,
        },
        {
          title: contents.consumersQuotaOptionLabel,
          icon: require("@/commons/assets/icons/icon-box.svg"),
          description: contents.consumersQuotaOptionDescription,
          value: EQuotaType.CONSUMERS_QUOTA,
        },
      ];
    },
    isLoading(): boolean {
      return this.$store.getters["isLoading"];
    },
    isSavingQuotaTypeChange(): boolean {
      return this.$store.getters["isSavingProperty"]("quotaTypeOfCurrentApi");
    },
    currentApi(): Api {
      return this.$store.getters["currentApi"];
    },
    rateLimits(): RateLimits {
      return this.$store.getters["rateLimits"];
    },
    rateLimitsForNewContracts(): RateLimits {
      return this.$store.getters["rateLimitsForNewContracts"];
    },
    allowedWindowLengthUnits(): EWindowLengthUnit[] {
      return getAllowedWindowLengthUnits(this.currentApi);
    },
  },
  async created() {
    this.selectedQuotaType = this.currentApi.quotaType || EQuotaType.API_QUOTA;
  },
  methods: {
    onClickOnAddApiRateLimit(): void {
      this.openApiRateLimitEditionModal(buildDefaultRateLimit());
    },
    onEditApiRateLimit(apiRateLimit: RateLimit): void {
      this.openApiRateLimitEditionModal(apiRateLimit);
    },
    onApiRateLimitActivationChange(isEnabling: boolean): void {
      this.createOrUpdateApiRateLimit({
        ...this.rateLimits.global,
        isEnabled: isEnabling,
      });
    },
    openApiRateLimitEditionModal(rateLimit: RateLimit): void {
      this.$store.commit("openLayerModal", {
        title: contentsForRateLimitEditionModal.modalTitleForApiRateLimit,
        component: markRaw(QuotaRateLimitEditionModal),
        props: {
          rateLimit,
          allowedWindowLengthUnits: getAllowedWindowLengthUnits(
            this.currentApi,
          ),
        },
        listeners: {
          onSubmit: this.createOrUpdateApiRateLimit,
        },
      });
    },
    onClickOnAddRateLimitForNewContracts(): void {
      this.openRateLimitForNewContractsEditionModal(buildDefaultRateLimit());
    },
    onEditRateLimitForNewContracts(rateLimitForNewContracts: RateLimit): void {
      this.openRateLimitForNewContractsEditionModal(rateLimitForNewContracts);
    },
    onRateLimitForNewContractsActivationChange(isEnabling: boolean): void {
      this.createOrUpdateRateLimitForNewContracts({
        ...this.rateLimitsForNewContracts.global,
        isEnabled: isEnabling,
      });
    },
    openRateLimitForNewContractsEditionModal(rateLimit: RateLimit): void {
      this.$store.commit("openLayerModal", {
        title:
          contentsForRateLimitEditionModal.modalTitleForRateLimitForNewContracts,
        component: markRaw(QuotaRateLimitEditionModal),
        props: {
          rateLimit,
          allowedWindowLengthUnits: getAllowedWindowLengthUnits(
            this.currentApi,
          ),
        },
        listeners: {
          onSubmit: this.createOrUpdateRateLimitForNewContracts,
        },
      });
    },
    onClickOnQuotaTypeOption(newQuotaType: EQuotaType): void {
      this.$store.commit("openModal", {
        title: contentsForQuotaTypeChangeModal.modalTitle,
        component: markRaw(QuotaTypeChangeModal),
        props: {
          newQuotaType,
        },
        listeners: {
          onSubmit: () => {
            this.updateQuotaType(newQuotaType);
            this.$store.commit("closeModal");
          },
        },
      });
    },
    async updateQuotaType(quotaType: EQuotaType): Promise<void> {
      this.$store.dispatch("updateQuotaTypeOfCurrentApi", quotaType);
      this.selectedQuotaType = quotaType;
    },
    createOrUpdateApiRateLimit(rateLimit: RateLimit): void {
      this.$store.dispatch("createOrUpdateApiRateLimit", rateLimit);
    },
    createOrUpdateRateLimitForNewContracts(rateLimit: RateLimit): void {
      this.$store.dispatch("createOrUpdateRateLimitForNewContracts", rateLimit);
    },
  },
};
</script>

<style lang="scss">
.quota-api-settings {
  display: flex;
  flex-direction: column;

  gap: var(--base-spacing);
}
.quota-api-settings__add-new-quota {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin-top: 1.5rem;
  background-color: var(--color-m-secondary-light);
  border: 1px dashed var(--color-light-grey);
  border-radius: 2.5rem;
}

.quota-api-settings__quota-type-options-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  margin-top: var(--base-spacing);
  margin-bottom: 4rem;
}
</style>
