<template>
  <div v-if="!isLoading" class="publishing-api">
    <div v-if="Object.keys(routesByZone).length === 0">
      <h3>{{ contents.youAreNotReadyToPublish }}</h3>
      <p class="publishing-api__incomplete-conditions">
        <span>{{ contents.yourApiCannotBePublished }}</span>
      </p>
    </div>
    <template v-else>
      <div class="publishing-api__content">
        <h3>{{ contents.header }}</h3>
        <div class="publishing-api__zones">
          <PublicationZoneCard
            v-for="zoneId of zoneIdsSortedByName"
            :key="zoneId"
            class="publishing-api__publication-zone-card"
            :zone="zones[zoneId]"
            :routes="routesByZone[zoneId]"
            :api="currentApi"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import PublicationZoneCard from "./PublicationZoneCard.vue";

import { Api } from "@/commons/domain/models/api";

import contents from "@/manager/contents/publishing-api";

export default {
  components: { PublicationZoneCard },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: null,
      contents,
    };
  },
  computed: {
    currentApi(): Api {
      return this.$store.getters["currentApi"];
    },
    zoneIdsSortedByName() {
      return Object.keys(this.routesByZone).sort((id1, id2) => {
        return (
          this.zones &&
          this.zones[id1] &&
          this.zones[id2] &&
          this.zones[id1].name.localeCompare(this.zones[id2].name)
        );
      });
    },
    zones() {
      return this.$store.getters["zones"];
    },
    routesByZone() {
      let routes = this.$store.getters["downstreamRoutes"];

      return routes.reduce((routesByZone, route) => {
        if (!routesByZone[route.zoneIds[0]]) {
          routesByZone[route.zoneIds[0]] = [];
        }
        routesByZone[route.zoneIds[0]].push(route);
        return routesByZone;
      }, {});
    },
    isLoading() {
      return this.$store.getters["isLoading"];
    },
    pages() {
      return this.$store.getters["managerDocumentationPagesList"];
    },
  },
  async mounted() {
    await this.$store.dispatch("loadApi", {
      apiId: this.id,
    });
  },
};
</script>

<style lang="scss">
@import "~@/commons/assets/styles/lib/vars/breakpoints";

.publishing-api {
  padding: 0;

  &__content {
    display: flex;
    flex-direction: column;

    gap: var(--base-spacing);
  }
}

.publishing-api__incomplete-conditions {
  display: inline;
}

.publishing-api__zones {
  display: flex;
  flex-flow: row wrap;
  gap: 2rem;
}

.publishing-api__publication-zone-card {
  width: calc(50% - 1rem); // -1rem is for half the gap size
}
/* stylelint-disable scss/no-global-function-names */
@media only screen and (max-width: map-get($breakpoints, "desktop-small")) {
  .publishing-api__publication-zone-card {
    width: 100%;
  }
}
</style>
