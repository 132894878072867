<template>
  <div class="contact-us">
    <div class="contact-us__title">
      <h1>{{ contents.readyToGetStarted }}</h1>
      <span class="contact-us__title--thin">
        {{ contents.contactUsOrCreateAccount }}
      </span>
    </div>
    <div class="contact-us__actions">
      <DvpButton
        v-if="!isLogged"
        :label="contents.signInButtonLabel"
        @click="
          () =>
            $router.push({
              name: 'login',
              query: { redirect: $route.fullPath },
            })
        "
      />
      <DvpButton
        theme="solid-neutral"
        :label="contents.helpButtonLabel"
        :href="supportUrl"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";

import DvpButton from "@/commons/components/Action/DvpButton.vue";

const contents = {
  contactUsOrCreateAccount: "Contact us or create an account",
  readyToGetStarted: "Ready to get started?",
  signInButtonLabel: "Sign in",
  helpButtonLabel: "Need help ?",
};

const store = useStore();

const isLogged = computed(() => {
  return store.getters["userIsLogged"];
});

const supportUrl = computed(() => {
  return store.getters["config/supportUrl"];
});
</script>

<style lang="scss">
@import "~@/commons/assets/styles/lib/breakpoints-mixin";

.contact-us {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  gap: var(--base-spacing);

  height: 30rem;

  @include respond-to("mobile-large") {
    width: 90vw;
  }
}

.contact-us__title {
  display: flex;
  flex-direction: column;

  align-items: center;
}

.contact-us__title--thin {
  @include set-heading-l;
  font-weight: 200;
}

.contact-us__actions {
  display: flex;
  gap: var(--base-spacing);
}
</style>
