<template>
  <div class="home-resume">
    <!--eslint-disable-next-line vue/no-v-html-->
    <h1 class="home-resume__title" v-html="contents.resumeHeaderTitle" />
    <div class="home-resume__buttons">
      <MButton
        v-if="!isLogged"
        :label="contents.signInButtonLabel"
        @click="
          () =>
            $router.push({
              name: 'login',
              query: { redirect: $route.fullPath },
            })
        "
      />
      <MButton
        v-else
        :label="contents.navigateToDashboardButtonLabel"
        @click="() => $router.push({ name: 'dashboard' })"
      />
    </div>
    <div class="home-resume__footer">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import { computed } from "vue";
import { useStore } from "vuex";

import content from "@/showcase/contents/home-resume";

const store = useStore();

const isLogged = computed(() => store.getters["userIsLogged"]);
const locationSettings = computed(
  () => store.getters["config/locationSettings"],
);
const contents = content[locationSettings.value.name];
</script>

<style lang="scss">
@import "~@/commons/assets/styles/lib/breakpoints-mixin";

.home-resume {
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
  height: 100%;

  text-align: center;

  gap: var(--base-spacing);
}

.home-resume__title {
  width: 45%;

  @include respond-to("mobile-small") {
    width: 90%;
    padding: var(--base-spacing-m);
  }
}
</style>
