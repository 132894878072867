import { Dictionary } from "@/commons/domain/models/dictionary";
import { Tag } from "@/commons/domain/models/tag";

import { State } from "./types";
import { ETagType } from "@/commons/store/types";

export function isLoading(state: State) {
  return Object.values(state.isLoading).some(
    (propertyIsLoading) => propertyIsLoading,
  );
}

export function isLoadingProperty(state: State) {
  return (propertyName: string) => {
    return state.isLoading[propertyName] || false;
  };
}

export function isSaving(state: State) {
  return Object.values(state.isSaving).some(
    (propertyIsSaving) => propertyIsSaving,
  );
}

export function isSavingProperty(state: State) {
  return (propertyName: string) => {
    return state.isSaving[propertyName] || false;
  };
}

export function actionFailing(state: State) {
  return state.actionFailing;
}

export function isModalOpen(state: State) {
  return state.openModal?.component != undefined;
}

export function zones(state: State) {
  return state.zones || {};
}

export function zonesList(state: State, getters) {
  return Object.values(getters.zones);
}

export function products(state: State) {
  return state.products || {};
}

export function productsList(state: State, getters) {
  return (
    getters.products &&
    getters.products.data &&
    Object.values(getters.products.data)
  );
}

function productsTotalPages(getters): number {
  return getters.products && getters.products.totalPages;
}

function productsTotalCount(getters): number {
  return getters.products && getters.products.totalCount;
}

function productsCurrentPage(getters): number {
  return getters.products && getters.products.currentPage;
}

function scopesProducts(state: State) {
  return state.scopesProducts || {};
}

function scopesProductsList(getters) {
  return (
    getters.scopesProducts &&
    getters.scopesProducts.data &&
    Object.values(getters.scopesProducts.data)
  );
}

function scopesProductsTotalPages(getters): number {
  return getters.scopesProducts && getters.scopesProducts.totalPages;
}

function scopesProductsTotalCount(getters): number {
  return getters.scopesProducts && getters.scopesProducts.totalCount;
}

function scopesProductsCurrentPage(getters): number {
  return getters.scopesProducts && getters.scopesProducts.currentPage;
}

export function tags(state: State) {
  return state.tags;
}

export function buTagsList(state: State, getters) {
  const tags: Dictionary<Tag> = getters.tags || {};
  return Object.values(tags).filter((tag) => tag.type === ETagType.BU);
}

export function platformTagsList(state: State, getters) {
  const tags: Dictionary<Tag> = getters.tags || {};
  return Object.values(tags).filter((tag) => tag.type === ETagType.PLATFORM);
}

export function apis(state: State) {
  return state.apis || {};
}

function apisList(state: State, getters) {
  return getters.apis && getters.apis.data && Object.values(getters.apis.data);
}

function apisTotalPages(state: State, getters) {
  return getters.apis && getters.apis.totalPages;
}

function apisTotalCount(state: State, getters) {
  return getters.apis && getters.apis.totalCount;
}

function previousPageRoute(state: State) {
  return state.previousRoute;
}

function isLoadingAnyOfProperties(state: State) {
  return (properties: string[]) => {
    return properties.some((property) => state.isLoading[property]);
  };
}

export default {
  isLoading,
  isLoadingProperty,
  isLoadingAnyOfProperties,
  isSaving,
  isSavingProperty,
  actionFailing,
  isModalOpen,
  zones,
  zonesList,
  products,
  productsList,
  productsTotalPages,
  productsTotalCount,
  productsCurrentPage,
  scopesProducts,
  scopesProductsList,
  scopesProductsTotalPages,
  scopesProductsTotalCount,
  scopesProductsCurrentPage,
  tags,
  buTagsList,
  platformTagsList,
  apis,
  apisList,
  apisTotalPages,
  apisTotalCount,
  previousPageRoute,
};
