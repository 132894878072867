<script setup lang="ts">
import MBadge from "@mozaic-ds/vue-3/src/components/badge/MBadge.vue";
import MDataTableV2 from "@mozaic-ds/vue-3/src/components/datatablev2/MDataTableV2.vue";
import MLoader from "@mozaic-ds/vue-3/src/components/loader/MLoader.vue";
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import MSelect from "@mozaic-ds/vue-3/src/components/select/MSelect.vue";
import { inject, PropType } from "vue";
import { computed } from "vue";
import { useStore } from "vuex";

import ButtonDelete from "@/commons/components/Buttons/ButtonDelete.vue";
import GroupTitleWithIconAndUsersNumber from "@/commons/components/ManageGroups/GroupTitleWithIconAndUsersNumber.vue";
import HelpLink from "@/commons/components/UserDocumentationLinks/HelpLink.vue";
import UserRoleIcon from "@/commons/components/UserRoleIcon.vue";

import { getGroupWithUsersAclsRepository } from "@/commons/repositories/libs/get-group-with-users-acls-repository";
import { isLocalGroup, isMCCGroup } from "@/commons/utils/group-utils";
import {
  mapRolesToMSelectOptions,
  ROLES_APPLICABLE_TO_GROUP,
} from "@/commons/utils/role.utils";
import { getUserName, isValidLDAP } from "@/commons/utils/user-utils";

import { AclUser } from "@/commons/domain/models/acl-user";
import { Group } from "@/commons/domain/models/group";
import {
  MDataTableV2HeaderItem,
  MSelectOption,
} from "@/commons/domain/models/mozaic";
import { CurrentUser } from "@/commons/domain/models/user";

import { ERole } from "@/commons/store/types";

import contents from "@/commons/contents/users-list";

const store = useStore();
const swal = inject("$swal");

const props = defineProps({
  users: {
    type: Array as PropType<AclUser[]>,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  allowActions: {
    type: Boolean,
    default: false,
  },
  withTitle: {
    type: Boolean,
    default: false,
  },
  group: {
    type: Object as PropType<Group>,
    default: undefined,
  },
  hideTableHeaders: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["groupUpdated", "onSelfRemoval"]);

const tableHeaders: MDataTableV2HeaderItem[] = [
  {
    value: "roleIcon",
    class: "users-list__role-icon-column",
  },
  {
    label: !props.hideTableHeaders ? contents.userNameColumnName : undefined,
    value: "name",
  },
  {
    label: !props.hideTableHeaders ? contents.ldapColumnName : undefined,
    value: "ldap",
    class: !props.allowActions ? "users-list__ldap-column" : undefined,
  },
  {
    value: "blockedBadge",
    class: !props.allowActions ? "users-list__blocked-column" : undefined,
  },
];

const actionTableHeaders: MDataTableV2HeaderItem[] = [
  {
    value: "changeRole",
    class: "users-list__change-role-column",
  },
  {
    value: "deleteUser",
    class: "users-list__delete-user-column",
  },
];

const headers = computed(() => {
  return props.allowActions
    ? [...tableHeaders, ...actionTableHeaders]
    : tableHeaders;
});

const roleOptions = computed((): MSelectOption[] => {
  return mapRolesToMSelectOptions(ROLES_APPLICABLE_TO_GROUP);
});

const currentUser = computed((): CurrentUser => store.getters["user"]);

const isCurrentUser = (user: AclUser): boolean => {
  return currentUser.value?.id === user.id;
};

/*
 * Actions
 */

const canChangeRole = (user: AclUser): boolean => {
  return user.allowedActions?.["set-role"] === true;
};

const changeRole = async (user: AclUser, newRole: ERole) => {
  await getGroupWithUsersAclsRepository().updateUserAcl(
    props.group.id,
    user.id,
    newRole,
  );
  emit("groupUpdated");
  store.commit("postSuccessNotification", {
    title: contents.userRoleSuccessfullyChanged,
  });
};

const canRemoveUser = (user: AclUser): boolean => {
  return user.allowedActions?.["remove"] === true;
};

const removeUser = async (user: AclUser) => {
  const result = await swal({
    titleText: contents.areYouSureYouWantToRemove(user.name),
  });

  if (result.isConfirmed) {
    await getGroupWithUsersAclsRepository().removeUserAcl(
      props.group.id,
      user.id,
    );
    emit("groupUpdated");

    if (isCurrentUser(user)) {
      emit("onSelfRemoval");
    }

    store.commit("postSuccessNotification", {
      title: contents.userSuccessfullyRemoved,
    });
  }
};

const displayTitle = computed(() => {
  return props.withTitle === true && props.group != undefined;
});
</script>

<template>
  <div class="users-list" :class="[{ 'users-list--gap': !hideTableHeaders }]">
    <GroupTitleWithIconAndUsersNumber
      v-if="displayTitle"
      :class="[{ 'users-list__title--hide-headers': hideTableHeaders }]"
      :group="group"
      :usersNumber="users.length"
    />
    <MLoader v-if="isLoading" />
    <MDataTableV2
      v-else-if="users != undefined && users.length > 0"
      class="users-list__table"
      :class="[{ 'users-list__table--hide-headers': hideTableHeaders }]"
      :headers="headers"
      :items="users"
    >
      <template #[`cell.roleIcon`]="{ item: user }">
        <UserRoleIcon :user="user" />
      </template>
      <template #[`cell.name`]="{ item: user }">
        <div :data-cy="`user-name-${user.id}`">
          <span>{{ getUserName(user.name, isCurrentUser(user)) }}</span>
        </div>
      </template>
      <template #[`cell.ldap`]="{ item: user }">
        <span v-if="isValidLDAP(user.username)">{{ user.username }}</span>
        <span v-else class="users-list__unknown-ldap">{{
          contents.unknownLdap
        }}</span>
      </template>
      <template #[`cell.blockedBadge`]="{ item: user }">
        <MBadge v-if="user.isBlocked" type="danger">{{
          contents.blockedBadgeLabel
        }}</MBadge>
      </template>
      <template v-if="allowActions" #[`cell.changeRole`]="{ item: user }">
        <MSelect
          :data-cy="`user-role-${user.id}`"
          size="s"
          :options="roleOptions"
          :modelValue="user.role.id"
          :disabled="!canChangeRole(user)"
          @update:modelValue="changeRole(user, $event)"
        />
      </template>
      <template
        v-if="allowActions && isLocalGroup(group)"
        #[`cell.deleteUser`]="{ item: user }"
      >
        <ButtonDelete
          :data-cy="`delete-user-${user.id}`"
          :disabled="!canRemoveUser(user)"
          @click="removeUser(user)"
        />
      </template>
    </MDataTableV2>
    <div v-else-if="users != undefined && users.length === 0">
      {{ contents.noMembers }}
    </div>

    <MNotification
      v-if="allowActions && isMCCGroup(group)"
      class="users-list__notification"
      type="information"
    >
      {{ contents.mccGroupInfo }}
      <template #footer>
        <HelpLink
          :href="group.linkMCC"
          :label="contents.goToMyCloudConsole"
          openInNewTab
        />
      </template>
    </MNotification>
  </div>
</template>

<style lang="scss">
.users-list {
  display: flex;
  flex-direction: column;
}
.users-list--gap {
  gap: 0.5rem;
}
.users-list__title--hide-headers {
  z-index: var(--z-index-foreground);
}
.users-list__table--hide-headers {
  position: relative;
  bottom: 1.5rem;
}
.users-list__table .mc-datatable__container {
  box-shadow: none;
}
.users-list__role-icon-column {
  width: 3rem;
}
.users-list__ldap-column {
  width: 8rem;
}
.users-list__blocked-column {
  width: 6rem;
}
.users-list__unknown-ldap {
  font-style: italic;
  color: var(--color-text-disabled);
}
.users-list__change-role-column {
  width: 9rem;
}
.users-list__delete-user-column {
  width: 3rem;
}
.users-list__notification {
  margin-top: 2rem;
}
</style>
