<template>
  <div v-if="!isLoading" class="routing-api">
    <DvpNotification v-if="!isLoading && validationError" type="danger">
      {{ validationError }}
    </DvpNotification>
    <DvpNotification v-if="apiHasPendingOtherGatewaysRequest" type="warning">{{
      contents.alertMessagePendingOtherGateway
    }}</DvpNotification>
    <h3>{{ contents.header }}</h3>

    <CreateTargetUrl
      class="routing-api__create"
      :formParent="form"
      :form="form.newValue"
    />

    <UpdateOrRemoveTargetUrl
      v-for="(targetUrlForm, index) in form.values"
      :key="`update-or-remove-route-${targetUrlForm.id}`"
      class="routing-api__update-or-remove"
      :form="targetUrlForm"
      :formParent="form"
      :positionInList="index + 1"
      :isDisabled="isSaving"
    />

    <BlackListRoutes />
  </div>
</template>

<script lang="ts">
import DvpNotification from "@/commons/components/Indicator/DvpNotification.vue";
import BlackListRoutes from "@/manager/views/ApiDetailRouting/BlackListRoutes.vue";

import CreateTargetUrl from "./CreateTargetUrl.vue";
import UpdateOrRemoveTargetUrl from "./UpdateOrRemoveTargetUrl.vue";

import { Route } from "@/commons/domain/models/route";
import { TargetUrlsListForm } from "@/manager/domain/forms/target-urls-list-form";

import contents from "@/manager/contents/routing-api";

export default {
  components: {
    CreateTargetUrl,
    DvpNotification,
    UpdateOrRemoveTargetUrl,
    BlackListRoutes,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      contents,
      form: TargetUrlsListForm.create(),
    };
  },
  computed: {
    validationError() {
      return this.form.firstError("values") || this.form.firstError("newValue");
    },
    isSaving() {
      return this.$store.getters["isSaving"];
    },
    isLoading() {
      return this.$store.getters["isLoading"];
    },
    upstreamRoutes(): Route[] {
      return this.$store.getters["upstreamRoutes"];
    },
    currentApi() {
      return this.$store.getters["currentApi"];
    },
    apiHasPendingOtherGatewaysRequest() {
      return this.$store.getters["apiHasPendingOtherGatewaysRequest"];
    },
  },
  watch: {
    upstreamRoutes: {
      immediate: true,
      deep: true,
      handler(newUpstreamRoutes) {
        this.form.init({
          newValue: { url: "", zoneIds: [] },
          values: newUpstreamRoutes,
        });
      },
    },
  },
  mounted() {
    this.$store.dispatch("availableZones", this.currentApi.id);
  },
};
</script>

<style lang="scss">
.routing-api {
  display: flex;
  flex-direction: column;

  gap: var(--base-spacing);
}
</style>
