<template>
  <Layout>
    <LayoutSection fullWidthContent noHorizontalPadding noVerticalPadding>
      <HomeMain class="home__main" />
    </LayoutSection>
    <LayoutSection fullWidthContent noHorizontalPadding noVerticalPadding>
      <HomeUseCases id="home-use-cases" class="home__use-cases" />
    </LayoutSection>
    <LayoutSection fullWidthContent noHorizontalPadding noVerticalPadding>
      <HomeTryIt id="home-try-it" class="home__try-it" />
    </LayoutSection>
    <LayoutSection fullWidthContent noHorizontalPadding noVerticalPadding>
      <HomeResume class="home__home-resume" />
    </LayoutSection>
    <LayoutSection fullWidthContent noHorizontalPadding noVerticalPadding>
      <FooterMenus />
    </LayoutSection>
  </Layout>
</template>

<script setup lang="ts">
import { computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import FooterMenus from "@/commons/components/FooterMenus.vue";
import Layout from "@/commons/components/Layout.vue";
import LayoutSection from "@/commons/components/LayoutSection.vue";
import HomeTryIt from "@/showcase/views/Home/HomeTryIt.vue";

import HomeMain from "./HomeMain.vue";
import HomeResume from "./HomeResume.vue";
import HomeUseCases from "./HomeUseCases.vue";

const store = useStore();
const router = useRouter();

const userIsLogged = computed(() => {
  return store.getters["userIsLogged"];
});

onMounted(() => {
  if (userIsLogged.value) {
    router.push({
      name: "dashboard",
    });
  }
});
</script>

<style lang="scss">
@import "~@/commons/assets/styles/lib/breakpoints-mixin";

$padding-desktop: 14vh;
$padding-try-it-desktop: 5vh 14vh;

.home__main,
.home__try-it,
.home__use-cases {
  position: relative;
  height: 92vh;
  background-color: var(--color-leroy-merlin);

  @include respond-to("mobile-small", "min") {
    &::before {
      position: absolute;
      top: 0;
      right: 10rem;
      width: 42vw;
      height: 100%;
      content: "";
      background: var(--color-background-interface);
    }
  }
}

.home__main,
.home__use-cases {
  padding: $padding-desktop;
  @include respond-to("mobile-large") {
    padding: var(--base-spacing-l);
  }
}

.home__try-it {
  padding: $padding-try-it-desktop;
  @include respond-to("mobile-large") {
    padding: 1rem 3rem;
  }
}

.home__main,
.home__try-it {
  &::before {
    transform: skewX(-25deg);
  }
}

.home__use-cases {
  &::before {
    transform: skewX(25deg);
  }
}

.home__home-resume {
  position: relative;
  height: 61vh;
}

@include respond-to("mobile-small") {
  .home__main,
  .home__try-it,
  .home__use-cases,
  .home__home-resume {
    width: 100vw;
    height: 100%;
  }
}
</style>
