<template>
  <div class="use-case-actions">
    <DvpButton
      v-if="!isLogged"
      :label="contents.buttonStartLabel"
      theme="bordered"
      @click="
        () =>
          $router.push({ name: 'login', query: { redirect: $route.fullPath } })
      "
    />

    <DvpButton
      :label="contents.buttonSupportLabel"
      theme="solid-neutral"
      :href="supportUrl"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";

import DvpButton from "@/commons/components/Action/DvpButton.vue";

import contents from "@/showcase/contents/use-case-actions";

defineProps({
  isFirst: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();

const isLogged = computed(() => {
  return store.getters["userIsLogged"];
});

const supportUrl = computed(() => {
  return store.getters["config/supportUrl"];
});
</script>

<style lang="scss">
.use-case-actions {
  position: relative;
  display: flex;

  gap: var(--base-spacing);
}
</style>
