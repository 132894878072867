<template>
  <Layout
    class="product-detail"
    :isLoading="isLoadingProduct"
    :loadingMessage="contents.loadingMessage"
  >
    <template #secondaryNavbar>
      <ProductDetailSecondaryNavbar
        v-if="productDetail"
        :product="productDetail"
      />
    </template>

    <template #sideMenu>
      <ProductDetailSideMenu
        v-if="productDetail"
        :productTitle="productDetail.name"
      />
    </template>

    <div class="product-detail__body">
      <CardContainer id="product-description">
        <h2 class="product-detail__page-title">{{ pageTitle }}</h2>
        <h3>{{ contents.description }}</h3>
        <span v-if="productDetail && productDetail.description">{{
          productDetail.description
        }}</span>
        <span v-else>{{ contents.noDescription }}</span>
      </CardContainer>

      <ProductApisList
        v-if="productDetail && productDetail.providedApis"
        :title="contents.providedApis"
        anchorId="product-provided-apis"
        :apis="productDetail.providedApis"
        :noItemsMessage="noProvidedApisMessage"
      />

      <ProductApisList
        v-if="productDetail && productDetail.usedApis"
        :title="contents.usedApis"
        anchorId="product-used-apis"
        :apis="productDetail.usedApis"
        :noItemsMessage="noUsedApisMessage"
      />
    </div>
  </Layout>
</template>

<script lang="ts">
import CardContainer from "@/commons/components/CardContainer.vue";
import Layout from "@/commons/components/Layout.vue";
import ProductDetailSecondaryNavbar from "@/documentation/components/navbar/ProductDetailSecondaryNavbar.vue";
import ProductDetailSideMenu from "@/documentation/components/side-menu/ProductDetailSideMenu.vue";
import ProductApisList from "@/documentation/views/ProductDetail/ProductApisList.vue";

import { updatePageTitle } from "@/commons/libs/router-update-page-meta";

import { Product } from "@/commons/domain/models/product";
import { getProductById } from "@/commons/services/product/product-service";

import contents from "@/documentation/contents/product-detail";

export default {
  components: {
    ProductApisList,
    CardContainer,
    Layout,
    ProductDetailSecondaryNavbar,
    ProductDetailSideMenu,
  },
  data() {
    return {
      contents,
      productDetail: undefined as Product,
      isLoadingProduct: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters["user"];
    },
    userIsInternal() {
      return this.user && this.user.isInternal;
    },
    pageTitle() {
      return `${contents.aboutPrefix} ${this.productTitle}`;
    },
    productTitle() {
      return this.productDetail ? this.productDetail.name : "";
    },
    noProvidedApisMessage() {
      return contents.noApiProvided(this.productTitle);
    },
    noUsedApisMessage() {
      return contents.noApiUsed(this.productTitle);
    },
  },
  async mounted() {
    const productId = this.$route.params.id;
    if (productId) {
      this.isLoadingProduct = true;
      this.productDetail = await getProductById(productId);

      if (this.productDetail !== undefined) {
        updatePageTitle(this.productDetail.name);
      }
      this.isLoadingProduct = false;
    }
  },
};
</script>

<style lang="scss">
.product-detail__page-title {
  margin-bottom: var(--base-spacing);
}

.product-detail__body {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 0.5rem;
}
</style>
