import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-9a9e2cd8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "request-manager" }
const _hoisted_2 = { class: "request-manager__left-panel" }
const _hoisted_3 = { class: "request-manager__filters" }
const _hoisted_4 = {
  key: 0,
  class: "gap-400 request-manager__pagination"
}
const _hoisted_5 = { class: "request-manager__right-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoCompleteSelect = _resolveComponent("AutoCompleteSelect")!
  const _component_RequestManagerList = _resolveComponent("RequestManagerList")!
  const _component_MPagination = _resolveComponent("MPagination")!
  const _component_Conversation = _resolveComponent("Conversation")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h4", null, _toDisplayString($data.contents.productListTitle), 1),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_AutoCompleteSelect, {
              modelValue: $options.selectedTypeItems,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.selectedTypeItems) = $event)),
              labelProperty: "label",
              keyProperty: "value",
              placeholder: "Filter by request type",
              multiple: "",
              options: $options.requestTypeItems
            }, null, 8, ["modelValue", "options"]),
            _createVNode(_component_AutoCompleteSelect, {
              modelValue: $options.selectedStatuses,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($options.selectedStatuses) = $event)),
              labelProperty: "label",
              keyProperty: "value",
              multiple: "",
              placeholder: "Filter by request status",
              options: $options.statuses
            }, null, 8, ["modelValue", "options"]),
            _createVNode(_component_AutoCompleteSelect, {
              modelValue: $options.selectedVisibility,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($options.selectedVisibility) = $event)),
              labelProperty: "name",
              keyProperty: "value",
              placeholder: "Filter by visibility",
              options: $options.visibilites,
              required: ""
            }, null, 8, ["modelValue", "options"])
          ]),
          _createVNode(_component_RequestManagerList),
          ($options.currentRequestsMaxCount > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_MPagination, {
                  length: $options.currentRequestsMaxCount,
                  value: $props.urlQuery.page,
                  onOnUpdatePage: _cache[3] || (_cache[3] = ($event: any) => ($options.changePage($event)))
                }, null, 8, ["length", "value"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Conversation, {
            currentUser: $options.currentUser,
            urlQuery: $props.urlQuery
          }, null, 8, ["currentUser", "urlQuery"])
        ])
      ])
    ]),
    _: 1
  }))
}