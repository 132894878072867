<template>
  <div class="tos">
    <TOSContent />
    <div class="tos__actions">
      <DvpButton :label="contents.confirmTOSBtnLabel" @click="confirm" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStore } from "vuex";

import DvpButton from "@/commons/components/Action/DvpButton.vue";
import TOSContent from "@/commons/components/TOSContent.vue";

import contents from "@/commons/contents/tos";

const store = useStore();

const confirm = () => {
  store.dispatch("acceptTOS");
};
</script>

<style lang="scss">
.tos {
  display: flex;
  flex-direction: column;
  height: 100%;

  gap: var(--base-spacing);

  &__actions {
    display: flex;
    align-self: flex-end;

    margin-bottom: var(--base-spacing);
  }
}
</style>
