<template>
  <div v-if="!isLoading" class="policies">
    <h3>{{ contents.policies }}</h3>

    <div class="tabs-light">
      <ul class="tabs-menu">
        <li class="tab-btn">
          <a
            :class="{
              current: currentTab === tabs.REQUEST_CUSTOM_HEADERS,
            }"
            @click="currentTab = tabs.REQUEST_CUSTOM_HEADERS"
          >
            {{ contents.requestHeaders }} ({{ numRequestCustomHeaders }})
          </a>
        </li>
        <li class="tab-btn">
          <a
            :class="{
              current: currentTab === tabs.RESPONSE_CUSTOM_HEADERS,
            }"
            @click="currentTab = tabs.RESPONSE_CUSTOM_HEADERS"
          >
            {{ contents.responseHeaders }} ({{ numResponseCustomHeaders }})
          </a>
        </li>
        <li v-if="allowQueryParamsTransformation" class="tab-btn">
          <a
            :class="{
              current: currentTab === tabs.CUSTOM_QUERY_PARAMS,
            }"
            @click="currentTab = tabs.CUSTOM_QUERY_PARAMS"
          >
            {{ contents.queryParams }} ({{ numCustomQueryParams }})
          </a>
        </li>
        <li v-if="allowTransformationPoliciesOrder" class="tab-btn">
          <a
            :class="{
              current: currentTab === tabs.ORDER_POLICIES,
            }"
            @click="currentTab = tabs.ORDER_POLICIES"
          >
            {{ contents.policiesOrder }} ({{ numPoliciesOrder }})
          </a>
        </li>
        <li class="tab-btn">
          <a
            :class="{
              current: currentTab === tabs.CORS,
            }"
            @click="currentTab = tabs.CORS"
          >
            {{ contents.cors }}
          </a>
        </li>
      </ul>
      <div
        v-if="currentTab === tabs.REQUEST_CUSTOM_HEADERS"
        class="tab-content"
      >
        <CustomProxyHeaders
          :api-id="apiId"
          :custom-proxy-headers="customRequestHeadersList"
          :type="ECustomProxyHeaderType.REQUEST"
        />
      </div>
      <div
        v-if="currentTab === tabs.RESPONSE_CUSTOM_HEADERS"
        class="tab-content"
      >
        <CustomProxyHeaders
          :api-id="apiId"
          :custom-proxy-headers="customResponseHeadersList"
          :type="ECustomProxyHeaderType.RESPONSE"
        />
      </div>
      <div v-if="currentTab === tabs.CORS" class="tab-content">
        <Cors :api-id="apiId" :cors="cors" />
      </div>
      <div v-if="currentTab === tabs.CUSTOM_QUERY_PARAMS" class="tab-content">
        <CustomQueryParams
          :api-id="apiId"
          :custom-query-params="customQueryParams"
        />
      </div>
      <div v-if="currentTab === tabs.ORDER_POLICIES" class="tab-content">
        <PoliciesOrder :api-id="apiId" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import sortBy from "lodash-es/sortBy";

import PoliciesOrder from "@/manager/views/ApiDetailPolicies/PoliciesOrder/PoliciesOrder.vue";

import Cors from "./Cors/Cors.vue";
import CustomProxyHeaders from "./CustomProxyHeaders/CustomProxyHeaders.vue";
import CustomQueryParams from "./QueryParams/CustomQueryParams.vue";

import { ECustomProxyHeaderType } from "@/commons/domain/models/custom-proxy-header";

import contents from "@/manager/contents/policies-api";

export default {
  name: "PoliciesApi",
  components: {
    PoliciesOrder,
    CustomProxyHeaders,
    Cors,
    CustomQueryParams,
  },
  data() {
    return {
      contents,
      currentTab: "",
      tabs: {
        REQUEST_CUSTOM_HEADERS: "REQUEST_CUSTOM_HEADERS",
        RESPONSE_CUSTOM_HEADERS: "RESPONSE_CUSTOM_HEADERS",
        CUSTOM_QUERY_PARAMS: "CUSTOM_QUERY_PARAMS",
        ORDER_POLICIES: "ORDER_POLICIES",
        CORS: "CORS",
      },
      ECustomProxyHeaderType: ECustomProxyHeaderType,
    };
  },
  computed: {
    currentApi() {
      return this.$store.getters["currentApi"];
    },
    apiId() {
      return (this.$route && this.$route.params.id) || "";
    },
    isLoading() {
      return this.$store.getters["isLoading"];
    },
    customRequestHeadersList() {
      return sortBy(this.$store.getters["customRequestHeadersList"], "name");
    },
    customResponseHeadersList() {
      return sortBy(this.$store.getters["customResponseHeadersList"], "name");
    },
    customQueryParams() {
      return this.$store.getters["customQueryParamsList"];
    },
    policiesOrder() {
      return this.$store.getters["policiesOrderList"];
    },
    cors() {
      return this.$store.getters["cors"];
    },
    numRequestCustomHeaders() {
      return this.$store.getters["numRequestCustomHeaders"];
    },
    numResponseCustomHeaders() {
      return this.$store.getters["numResponseCustomHeaders"];
    },
    numCustomQueryParams() {
      return this.$store.getters["numCustomQueryParams"];
    },
    numPoliciesOrder() {
      return this.$store.getters["policiesOrder"].length;
    },
    allowQueryParamsTransformation() {
      return this.$store.getters["apiAllowQueryParamsTransformation"];
    },
    allowTransformationPoliciesOrder() {
      return this.$store.getters["apiAllowTransformationPoliciesOrder"];
    },
  },
  mounted() {
    this.currentTab = "REQUEST_CUSTOM_HEADERS";
  },
};
</script>

<style lang="scss">
.policies tr:last-child td {
  border: none;
}

.policies table tr td {
  padding-right: 1rem;
  vertical-align: top;
}

.policies {
  display: flex;
  flex-direction: column;

  gap: var(--base-spacing);

  td:nth-child(1),
  td:nth-child(2),
  th:nth-child(1),
  th:nth-child(2) {
    width: 45%;
  }
  td:nth-child(3),
  th:nth-child(3) {
    width: 10%;
  }
}

.policies .btn-wide.btn-transparent {
  width: 100%;
  height: auto;
  padding: 1.2rem;
  font-size: 1rem;
  font-weight: 700;
  color: var(--color-text);
  text-transform: initial;
  border: dashed 1px var(--color-light-grey);
  border-radius: 0;
}

.policies .btn-wide.btn-transparent i {
  margin-right: 0.5rem;
  font-size: 2rem;
  font-weight: 700;
  color: var(--color-primary);
}
</style>
