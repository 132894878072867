<template>
  <div class="footer-menus">
    <div class="footer-menus__content">
      <div
        v-for="(menu, index) in navigationMenus"
        :key="`footer-menus-menu-${index}`"
        v-bind="menu"
        class="footer-menus__menu-content"
      >
        <h5 class="footer-menus__menu-header">{{ menu.label }}</h5>
        <HelpLink
          v-for="(item, itemIndex) in menu.items"
          :key="`footer-menus-menu-item-${itemIndex}`"
          :withIcon="item.isExternalLink === true"
          :href="
            item.isExternalLink
              ? item.urlOrRoute
              : convertRouteToHref(item.urlOrRoute)
          "
          :label="item.label"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";

import HelpLink from "@/commons/components/UserDocumentationLinks/HelpLink.vue";

import { getSupportMenuConf } from "@/commons/libs/get-support-menu-conf";
import { getUseCasesMenuConf } from "@/commons/libs/get-use-cases-menu-conf";
import { convertRouteToHref } from "@/commons/utils/route-utils";

const store = useStore();

const supportUrl = computed(() => store.getters["config/supportUrl"]);

const navigationMenus = computed(() => [
  getUseCasesMenuConf(),
  getSupportMenuConf(supportUrl.value),
]);
</script>

<style lang="scss">
@import "~@/commons/assets/styles/lib/breakpoints-mixin";

.footer-menus {
  position: relative;

  display: flex;
  justify-content: center;
  height: 18rem;

  margin-top: 3rem;
  background-color: var(--color-leroy-merlin);

  &::before {
    position: absolute;
    top: -4vh;
    left: 0;
    width: 100%;
    height: 18vh;
    content: "";
    background-color: var(--color-leroy-merlin);
    transform: skewY(2deg);
  }
}

.footer-menus__content {
  position: relative;

  display: flex;
  justify-content: space-between;

  width: 40%;

  gap: var(--base-spacing);

  @include respond-to("mobile-small") {
    width: 80vw;
  }
}

.footer-menus__menu-content {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: var(--base-spacing);
}

.footer-menus__menu-header {
  height: 2rem;
}
</style>
