<template>
  <div :class="['use-case-feature', { 'use-case-feature--inversed': isOdd }]">
    <div class="use-case-feature__description">
      <div>
        <h1 class="use-case-feature__title">{{ title }}</h1>
        <h2 class="use-case-feature__kicker">{{ kicker }}</h2>
      </div>
      <div class="use-case-feature__key-features">
        <UseCaseFeatureItem
          v-for="(keyFeature, keyFeatureIndex) in keyFeatures"
          :key="`use-case-feature-key-${keyFeatureIndex}`"
          :feature="keyFeature"
        />
      </div>
      <UseCaseActions class="use-case-feature__actions" />
    </div>
    <div class="use-case-feature__image">
      <img alt="Use case image" :src="image" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType } from "vue";

import UseCaseActions from "./UseCaseActions.vue";
import UseCaseFeatureItem from "./UseCaseFeatureItem.vue";

const props = defineProps({
  index: {
    type: Number,
    default: 0,
  },
  title: {
    type: String,
    default: "",
  },
  kicker: {
    type: String,
    default: "",
  },
  keyFeatures: {
    type: Array as PropType<String[]>,
    default: () => [],
  },
  image: {
    type: String,
    default: null,
  },
  carousel: {
    type: Array,
    default: () => [],
  },
});

const isOdd = computed(() => props.index % 2);
</script>

<style lang="scss">
@import "~@/commons/assets/styles/lib/breakpoints-mixin";

.use-case-feature {
  position: relative;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  max-width: 91.4rem;

  padding: clamp(1rem, 3vw, 3rem);
  margin: 0 auto;
  gap: clamp(1rem, 2vw, 2rem);
}

.use-case-feature--inversed {
  flex-direction: row-reverse;
}

.use-case-feature__description {
  display: flex;
  flex: 0.5;
  flex-direction: column;

  gap: var(--base-spacing);
}

.use-case-feature__image {
  display: flex;
  flex: 0.5;
}

.use-case-feature__image img {
  max-width: 95%;
  height: auto;
  object-fit: contain;
}

.use-case-feature__title {
  @include set-heading-l;
}

.use-case-feature__kicker {
  @include set-heading-s;
  font-weight: 200;
}

.use-case-feature__key-features {
  display: flex;
  flex-direction: column;

  gap: var(--base-spacing);
}

.use-case-feature__key-feature {
  display: flex;
  gap: var(--base-spacing-s);
}

.use-case-feature__feature-icon {
  flex: 0.1;
  color: var(--color-brand-secondary);
}

.use-case-feature__feature-text {
  flex: 0.9;
}

.use-case-feature__actions {
  align-self: center;
  margin-top: var(--base-spacing);
}

@include respond-to("mobile-large") {
  .use-case-feature {
    flex-direction: column;
    text-align: center;
  }

  .use-case-feature__description {
    align-items: center;
    text-align: start;
  }

  .use-case-feature__image {
    justify-content: center;
    order: -1;
  }
}
</style>
