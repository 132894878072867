<template>
  <Layout class="products-catalog" :isLoading="false">
    <LayoutSection background="primary" noVerticalPadding fullWidthContent>
      <LayoutContentHeader
        :title="contents.title"
        :description="contents.description"
      />
    </LayoutSection>
    <LayoutSection
      background="secondary"
      class="products-catalog__main-section"
    >
      <div class="products-catalog__filters-container">
        <div class="products-catalog__filters-on-right-side">
          <SearchTextFilter
            class="products-catalog__search-filter"
            queryParamName="term"
            size="s"
            :queryParamValue="urlQuery.term"
            :placeholder="contents.searchPlaceholder"
            data-cy="search-filter"
          />
          <MButton
            v-if="userIsInternal"
            data-cy="more-filters-button"
            icon="DisplayFilter24"
            theme="bordered"
            size="s"
            :label="contents.moreFiltersButtonLabel"
            @click="openFilters"
          />
        </div>

        <TagsAppliedFilters
          data-cy="filters-taglist"
          :appliedFiltersTagList="appliedFiltersTagList"
          @filterRemoved="removeFilter"
        />
      </div>

      <ListProductsCatalog :products="productsList" />

      <QueryParamPagination
        v-if="productsTotalPages"
        :totalPagesNumber="productsTotalPages"
      />
    </LayoutSection>
  </Layout>
</template>

<script lang="ts">
import { ProductListUrlQuery } from "@/documentation/routes";
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";

import SearchTextFilter from "@/commons/components/Filters/SearchTextFilter.vue";
import TagsAppliedFilters from "@/commons/components/Filters/TagsAppliedFilters.vue";
import Layout from "@/commons/components/Layout.vue";
import LayoutContentHeader from "@/commons/components/LayoutContentHeader.vue";
import LayoutSection from "@/commons/components/LayoutSection.vue";
import QueryParamPagination from "@/commons/components/QueryParamPagination.vue";
import ModalFiltersProductCatalog from "@/documentation/components/ModalFiltersProductCatalog.vue";
import ListProductsCatalog from "@/documentation/views/ProductsCatalog/ListProductsCatalog.vue";

import {
  buildProductCatalogFetchParams,
  removeIdFromUrlQuery,
} from "@/commons/utils/filter-utils";
import { tagsToMTagListItems } from "@/commons/utils/mozaic-utils";
import { changeUrlQuery, replaceUrlQuery } from "@/commons/utils/route-utils";

import { EMTagListType, MTagListItem } from "@/commons/domain/models/mozaic";
import { Tag } from "@/commons/domain/models/tag";
import { FiltersProductCatalogFormData } from "@/commons/forms/filters-product-catalog-form";

import contents from "@/documentation/contents/product-list";

const contentsForFiltersModal = require("@/documentation/contents/modal-filters-product-catalog");

export default {
  components: {
    TagsAppliedFilters,
    QueryParamPagination,
    Layout,
    LayoutSection,
    ListProductsCatalog,
    SearchTextFilter,
    LayoutContentHeader,
    MButton,
  },
  props: {
    urlQuery: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contents,
    };
  },
  computed: {
    userIsLogged() {
      return this.$store.getters["userIsLogged"];
    },
    userIsInternal() {
      return this.$store.getters["userIsInternal"];
    },
    platforms() {
      return this.$store.getters["platformTagsList"];
    },
    productsTotalPages() {
      return this.$store.getters["productsTotalPages"];
    },
    productsList() {
      return this.$store.getters["productsList"];
    },
    platformsFromUrlQuery(): Tag[] {
      return this.urlQuery.platformsIds
        ? this.platforms.filter((p) =>
            this.urlQuery.platformsIds.includes(p.id),
          )
        : [];
    },
    platformsTagListItems(): MTagListItem[] {
      return this.platformsFromUrlQuery
        ? tagsToMTagListItems(
            this.platformsFromUrlQuery,
            EMTagListType.REMOVABLE,
          )
        : [];
    },
    appliedFiltersTagList(): MTagListItem[] {
      return [...this.platformsTagListItems];
    },
  },
  watch: {
    userIsLogged(userIsLogged) {
      if (!userIsLogged) {
        replaceUrlQuery({}); // clear query params
      }
    },
    async urlQuery(newUrlQuery: ProductListUrlQuery) {
      await this.loadProducts(newUrlQuery);
    },
  },
  async mounted() {
    await this.loadProducts(this.urlQuery);
  },
  methods: {
    openFilters() {
      this.$store.commit("openLayerModal", {
        title: contentsForFiltersModal.moreFiltersHeader,
        component: ModalFiltersProductCatalog,
        props: {
          formData: {
            platformsIds: [...this.urlQuery.platformsIds],
          },
        },
        listeners: {
          submitted: async (updatedFormData: FiltersProductCatalogFormData) => {
            await this.changeUrlQuery(updatedFormData);
          },
        },
      });
    },
    async changeUrlQuery(newUrlQuery: any) {
      await changeUrlQuery({ ...newUrlQuery, page: 1 });
    },
    async resetFilters() {
      await replaceUrlQuery({});
    },
    async removeFilter(filterId: string) {
      const updatedUrlQuery = removeIdFromUrlQuery(filterId, this.urlQuery);
      await this.changeUrlQuery(updatedUrlQuery);
    },
    async loadProducts(urlQuery: ProductListUrlQuery) {
      await this.$store.dispatch(
        "loadProducts",
        buildProductCatalogFetchParams(urlQuery),
      );
    },
  },
};
</script>
<style lang="scss">
.products-catalog__filters-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}
.products-catalog__filters-on-right-side {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
  gap: 1rem;
}

.products-catalog__search-filter {
  width: 22rem;
}

.products-catalog__main-section {
  display: flex;
  flex: 1;
}
</style>
