<template>
  <CardContainer class="use-case-card">
    <DvpIcon name="MonochromeRelayLocation64" color="var(--color-primary)" />
    <h2 class="user-case-card__title">{{ title }}</h2>
    <p>{{ description }}</p>
    <DvpButton
      :label="contents.buttonLabel"
      @click="() => $router.push({ path })"
    />
  </CardContainer>
</template>

<script setup lang="ts">
import DvpButton from "@/commons/components/Action/DvpButton.vue";
import CardContainer from "@/commons/components/CardContainer.vue";
import DvpIcon from "@/commons/components/Media/DvpIcon.vue";

const contents = {
  buttonLabel: "View case study",
};

defineProps({
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    default: "",
  },
  path: {
    type: String,
    default: "",
  },
});
</script>

<style lang="scss">
.use-case-card {
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;

  width: 23rem;

  gap: var(--base-spacing);
}

.user-case-card__title {
  text-align: center;
}
</style>
