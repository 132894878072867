import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "user-profile__informations" }
const _hoisted_2 = { class: "user-profile__basic-information" }
const _hoisted_3 = { class: "user-profile__other-informations" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutContentHeader = _resolveComponent("LayoutContentHeader")!
  const _component_LayoutSection = _resolveComponent("LayoutSection")!
  const _component_UserInformations = _resolveComponent("UserInformations")!
  const _component_UserCookies = _resolveComponent("UserCookies")!
  const _component_UserSensitiveModifications = _resolveComponent("UserSensitiveModifications")!
  const _component_Layout = _resolveComponent("Layout")!

  return ($options.user)
    ? (_openBlock(), _createBlock(_component_Layout, {
        key: 0,
        class: "user-profile"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_LayoutSection, {
            background: "primary",
            noVerticalPadding: "",
            fullWidthContent: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_LayoutContentHeader, {
                title: $data.contents.userProfileTitle,
                description: $options.description
              }, null, 8, ["title", "description"])
            ]),
            _: 1
          }),
          _createVNode(_component_LayoutSection, {
            background: "secondary",
            class: "user-profile__information-section"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_UserInformations, { user: $options.user }, null, 8, ["user"])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_UserCookies),
                  (!$options.user.isInternal)
                    ? (_openBlock(), _createBlock(_component_UserSensitiveModifications, {
                        key: 0,
                        user: $options.user
                      }, null, 8, ["user"]))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}