<template>
  <div class="home-try-it">
    <div class="home-try-it__content">
      <div class="home-try-it__header">
        <!--eslint-disable-next-line vue/no-v-html-->
        <h1 class="home-try-it__header-title" v-html="contents.title" />
        <p class="home-try-it__header-description">
          {{ contents.description }}
        </p>
      </div>
      <div class="home-try-it__code-example">
        <Editor :value="contents.codeExample" />
        <div class="home-try-it__buttons">
          <MButton
            theme="solid-neutral"
            :label="contents.helpButtonLabel"
            :href="supportUrl"
          />
          <MButton
            :label="contents.startButtonLabel"
            @click="() => $router.push({ name: 'dashboard' })"
          />
        </div>
      </div>
    </div>
    <div class="home-try-it__steps">
      <div
        v-for="(step, index) in contents.steps"
        :key="`home-try-it-step-${index}`"
        class="home-try-it__step"
      >
        <div class="home-try-it__step-number">{{ index + 1 }}</div>
        <div>
          <div class="home-try-it__step-image">
            <img :src="step.image" class="home-try-it__img" />
          </div>
          <h3 class="home-try-it__step-title">{{ step.title }}</h3>
          <p class="home-try-it__step-description">{{ step.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import { computed } from "vue";
import { useStore } from "vuex";

import Editor from "./Editor.vue";

import content from "@/showcase/contents/home-try-it";

const store = useStore();

const supportUrl = computed(() => store.getters["config/supportUrl"]);

const locationSettings = computed(
  () => store.getters["config/locationSettings"],
);

const contents = computed(() => content[locationSettings.value.name]);
</script>

<style lang="scss">
@import "~@/commons/assets/styles/lib/breakpoints-mixin";

.home-try-it {
  display: flex;
  justify-content: space-between;

  @include respond-to("mobile-small") {
    flex-direction: column;
    gap: var(--base-spacing-l);
  }
}

.home-try-it__img {
  max-width: 5rem;
  height: auto;
}

.home-try-it__header {
  text-align: left;
}

.home-try-it__header-title,
.home-try-it__header-description {
  font-weight: 200;
}

.home-try-it__header-title {
  @include set-heading-l;
}

.home-try-it__header-description {
  @include set-text-l;
}

.home-try-it__content {
  position: relative;

  display: flex;
  flex: 0.5;
  flex-direction: column;

  gap: var(--base-spacing);
}

.home-try-it__code-example {
  z-index: var(--z-index-foreground);
  display: flex;
  flex-direction: column;

  gap: var(--base-spacing);
}

.home-try-it__buttons {
  display: flex;
  justify-content: center;
  gap: var(--base-spacing);
}

.home-try-it__steps {
  z-index: var(--z-index-foreground);

  display: flex;
  flex: 0.4;
  flex-direction: column;

  gap: var(--base-spacing);

  @include respond-to("mobile-small") {
    padding: var(--base-spacing-m);
    background-color: var(--color-white);
  }
}

.home-try-it__step {
  display: flex;
  align-items: center;

  gap: var(--base-spacing);
}

.home-try-it__step-number {
  font-size: 4.6rem;
  font-weight: 700;
  line-height: 3.4rem;

  color: var(--color-leroy-merlin);
}

.home-try-it__step-image {
  display: block;
}

.home-try-it__step-title {
  @include set-heading-xs;
}

.home-try-it__step-description {
  @include set-text-m;

  width: 80%;
  font-weight: 300;
}
</style>
