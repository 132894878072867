import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutContentHeader = _resolveComponent("LayoutContentHeader")!
  const _component_LayoutSection = _resolveComponent("LayoutSection")!
  const _component_GroupsListingSection = _resolveComponent("GroupsListingSection")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, { class: "groups-settings" }, {
    default: _withCtx(() => [
      _createVNode(_component_LayoutSection, {
        background: "primary",
        noVerticalPadding: "",
        fullWidthContent: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_LayoutContentHeader, {
            title: $data.contents.myGroups
          }, null, 8, ["title"])
        ]),
        _: 1
      }),
      ($data.pagedPersonalGroups != undefined)
        ? (_openBlock(), _createBlock(_component_GroupsListingSection, {
            key: 0,
            groups: $options.personalGroups,
            totalPages: $options.personalGroupsTotalPages,
            searchText: $props.urlQuery.term,
            onGroupUpdated: $options.loadPersonalGroups,
            onGroupDeleted: $options.onGroupDeleted
          }, null, 8, ["groups", "totalPages", "searchText", "onGroupUpdated", "onGroupDeleted"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}